import { faChevronLeft, faChevronRight, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import ExpandableButton from 'components/molecules/ExpandableButton';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { AuditTrailData } from '../hooks/useAuditTrailData';

interface AuditTrailProps {
  auditTrailData: AuditTrailData[];
  currentPage: number;
  totalPages: number;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  isFetchingAuditTrail: boolean;
  refetchAuditTrail: () => void;
  resetAuditTrailPage: () => void;
  setAuditTrailPage: (x: number) => void;
  auditTrailPage: number;
  goToPage: (value: number) => void;
}

const AuditTrail: React.FC<AuditTrailProps> = ({
  auditTrailData,
  currentPage,
  totalPages,
  goToPrevPage,
  goToNextPage,
  isFetchingAuditTrail,
  refetchAuditTrail,
  resetAuditTrailPage,
  setAuditTrailPage,
  auditTrailPage,
  goToPage,
}) => {
  const [isAuditTrailOpen, setIsAuditTrailOpen] = useState(false);
  const [showMoreContent, setShowMoreContent] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const wasAuditTrailOpen = useRef(false);
  const previousPage = useRef<number>(1);
  const [pageInput, setPageInput] = useState<string>(String(currentPage));
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  const toggleAuditTrailDropdown = () => {
    if (!isAuditTrailOpen && currentPage !== 1) {
      resetAuditTrailPage();
    }
    setIsAuditTrailOpen(!isAuditTrailOpen);
  };

  useEffect(() => {
    if (isAuditTrailOpen) {
      refetchAuditTrail();
    }
  }, [isAuditTrailOpen, refetchAuditTrail]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsAuditTrailOpen(false);
        resetAuditTrailPage();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  const openShowMoreModal = (content: string) => {
    wasAuditTrailOpen.current = isAuditTrailOpen;
    previousPage.current = auditTrailPage;
    setIsAuditTrailOpen(false);
    setShowMoreContent(content);
  };

  const closeShowMoreModal = async () => {
    setShowMoreContent(null);
    setAuditTrailPage(previousPage.current);
    await new Promise((resolve) => setTimeout(resolve, 0));
    setIsAuditTrailOpen(true);
    refetchAuditTrail();
  };

  const handleViewSingleFact = async (factId: string) => {
    Swal.fire({
      title: 'Locating fact...',
      text: 'Please wait while we locate the fact.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const resp = await fetch(`${APIBaseChronos}/client/case/fact/${caseId}/pageNumber/${factId}`, fetchConfigGET);
    const data = await resp.json();
    const rowNumber = data.rowNumber;
    const page = Math.ceil(rowNumber / 50) - 1;
    goToPage(page);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.toString());
    searchParams.set('keyFactId', factId);

    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());

    Swal.close(); // Close the loading indicator
    setIsAuditTrailOpen(false); // Close the audit trail dropdown
    setAuditTrailPage(1);
  };

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(e.target.value);
  };

  const handlePageInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const pageNumber = Math.max(1, Math.min(totalPages, parseInt(pageInput, 10) || 1));
      setAuditTrailPage(pageNumber);
      setPageInput(String(pageNumber));
      await new Promise((resolve) => setTimeout(resolve, 0));
      refetchAuditTrail();
    }
  };

  useEffect(() => {
    setPageInput(String(currentPage));
  }, [currentPage]);

  const getPreviousOrOriginal = (edit: AuditTrailData) => {
    const hasPrevious = Object.values(edit.previous || {}).some((value) => value !== null);

    return hasPrevious ? edit.previous : edit.original;
  };

  const getSingleChange = (edit: AuditTrailData) => {
    const comparisonValues = getPreviousOrOriginal(edit);

    const fields = [
      {
        field: 'Subject Matter',
        previous: comparisonValues?.subject_matter,
        current: edit.subject_matter,
      },
      {
        field: 'Fact Date',
        previous: comparisonValues?.date_of_subject_text,
        current: edit.date_of_subject_text,
      },
      {
        field: 'Document Date',
        previous: comparisonValues?.document_date_text,
        current: edit.document_date_text,
      },
      {
        field: 'Action Described',
        previous: comparisonValues?.action_described,
        current: edit.action_described,
      },
      {
        field: 'Relevance Reason',
        previous: comparisonValues?.relevance_reason,
        current: edit.relevance_reason,
      },
      {
        field: 'Important',
        previous: comparisonValues?.important,
        current: edit.important,
      },
    ];

    return fields.find((change) => change.current !== change.previous);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <ExpandableButton
        icon={faClock}
        onClick={toggleAuditTrailDropdown}
        className="py-2 hover:bg-gray-100 flex items-center rounded border text-blue-600 transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center"
      >
        <p className="text-sm">Audit Trail</p>
      </ExpandableButton>

      {isAuditTrailOpen && (
        <div className="absolute right-0 mt-2 bg-white shadow-lg rounded-lg p-4 z-10" style={{ width: '477px' }}>
          {isFetchingAuditTrail ? (
            <div className="flex justify-center items-center">
              <StageSpinner size={30} color="#4161FF" />
            </div>
          ) : (
            <div className="overflow-y-auto" style={{ maxHeight: '400px' }}>
              {auditTrailData?.map((edit, index) => {
                const change = getSingleChange(edit);

                if (!change) return null;

                return (
                  <div key={index} className="mb-4">
                    <p className="text-sm font-semibold">
                      {edit.editor_email} <span className="font-normal">edited</span>{' '}
                      <span
                        className="text-blue-500 cursor-pointer"
                        onClick={() => handleViewSingleFact(edit.event_id)}
                      >
                        {change.field}
                      </span>
                    </p>
                    <p className="text-xs text-gray-500">{new Date(edit.edited_date).toLocaleString()}</p>
                    <div className="pl-4 mt-2">
                      <div className="mb-2 text-xs">
                        <p className="line-through text-gray-500 overflow-hidden text-ellipsis whitespace-pre-wrap">
                          {change.previous?.length > 100 ? `${change.previous.slice(0, 100)}...` : change.previous}
                        </p>
                        <p className="overflow-hidden text-ellipsis whitespace-pre-wrap">
                          {change.current?.length > 100 ? `${change.current.slice(0, 100)}...` : change.current}
                        </p>
                        {(change.previous?.length > 100 || change.current?.length > 100) && (
                          <button
                            onClick={() =>
                              openShowMoreModal(`Previous: ${change.previous}\nCurrent: ${change.current}`)
                            }
                            className="text-blue-500 hover:text-blue-700 text-xs mt-1"
                          >
                            Show more
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="flex justify-between mt-4 items-center">
            <button
              onClick={goToPrevPage}
              disabled={currentPage <= 1}
              className="px-1 py-1 rounded cursor-pointer disabled:cursor-not-allowed hover:opacity-80"
              style={{ backgroundColor: '#ECEFFF' }}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="text-gray-700 mx-2"
                style={{
                  color: currentPage > 1 ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)',
                }}
              />
            </button>
            <div className="flex items-center">
              <input
                type="number"
                value={pageInput}
                onChange={handlePageInputChange}
                onKeyDown={handlePageInputKeyDown}
                className="text-center w-14 border border-gray-300 rounded-md mx-1 px-1 h-8 text-sm no-arrows"
                min="1"
                max={totalPages}
                style={{ borderRadius: '5px', border: '1px solid var(--black-10, #E7E7E7)' }}
              />
              <span className="text-sm text-gray-600">of {totalPages}</span>
            </div>
            <button
              onClick={goToNextPage}
              disabled={currentPage >= totalPages}
              className="px-1 py-1 rounded cursor-pointer disabled:cursor-not-allowed hover:opacity-80"
              style={{ backgroundColor: '#ECEFFF' }}
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-700 mx-2"
                style={{
                  color: currentPage < totalPages ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)',
                }}
              />
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={!!showMoreContent}
        onRequestClose={closeShowMoreModal}
        contentLabel="Show More"
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-6 rounded-lg shadow-lg max-w-lg w-full z-50"
      >
        <h2 className="text-xl font-bold mb-4">Change Details</h2>
        <pre className="whitespace-pre-wrap text-sm">{showMoreContent}</pre>
        <button onClick={closeShowMoreModal} className="mt-4 text-blue-500 hover:text-blue-700">
          Close
        </button>
      </Modal>
    </div>
  );
};

export default AuditTrail;
