import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery, UseQueryResult } from 'react-query';

function useFetchDocs(
  caseId: string | null,
  page?: number,
  pageSize?: number,
  sorting: SortingState = [],
  filters: ColumnFiltersState = [],
  searchQuery: string | null = null,
): UseQueryResult<any, unknown> {
  const { fetchConfigGET } = useGetFetchConfig();

  return useQuery<any, unknown>(
    ['userDocs', caseId, page, pageSize, sorting, filters, searchQuery],
    async () => {
      if (!caseId) {
        throw new Error('Case ID is required');
      }

      // Generate the sorting query string
      const sortParams = sorting.map((sort) => `sort_${sort.id}=${sort.desc ? 'desc' : 'asc'}`).join('&');

      // Generate the filtering query string
      const filterParams = filters
        .map((filter) => {
          if (Array.isArray(filter.value)) {
            return `filter_${filter.id}=${filter.value.map((val) => val.value).join(',')}`;
          }
          return `filter_${filter.id}=${filter.value}`;
        })
        .join('&');

      // Encode the search query
      const encodedSearchQuery = searchQuery ? `&searchQuery=${encodeURIComponent(searchQuery)}` : '';

      // Construct the full query URL, conditionally including page and pageSize
      const url = `${APIBaseChronos}/client/case/doc/${caseId}${
        page !== undefined ? `?page=${page}` : ''
      }${pageSize !== undefined ? `&pageSize=${pageSize}` : ''}${
        sortParams ? `&${sortParams}` : ''
      }${filterParams ? `&${filterParams}` : ''}${encodedSearchQuery}`;

      const response = await fetch(url, fetchConfigGET);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.json();
    },
    {
      enabled: !!caseId, // Ensure caseId is not null before fetching
      keepPreviousData: true, // Keep previous data while fetching new data for better UX
    },
  );
}

export default useFetchDocs;
