import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useMutation } from 'react-query';

interface CreateChronologyParameters {
  caseId: string | null;
  chronologyId: string | null;
  type: string | null;
}

interface HookProps {
  onSuccess: (data: CreateChronologyResponse) => void;
  onError: (data: ApiError) => void;
}

export interface CreateChronologyResponse {
  msg: string;
  type: string;
  lambdaResponse: {
    case_id: string;
    chronology_id: string;
    is_new: string;
    is_processed: string;
  };
}
export interface ApiError {
  statusCode: number;
  message: string;
}

export const useCreateChronology = ({ onSuccess, onError }: HookProps) => {
  const { getFetchConfig } = useGetFetchConfig();

  const createChronology = useMutation(
    async ({ caseId, chronologyId, type }: CreateChronologyParameters) => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          caseId,
          add_to_existing: !!chronologyId,
          existing_chronology_id: chronologyId,
          type: type,
        },
      });
      const res = await fetch(`${APIBaseChronos}/client/case/chronology`, fetchConfig);
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json(); // Return the response for use in onSuccess
    },
    {
      onSuccess: (data: CreateChronologyResponse) => {
        onSuccess(data);
      },
      onError: (error: ApiError) => {
        onError(error);
      },
    },
  );

  return {
    createChronology,
  };
};
