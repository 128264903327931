import { useCallback, useEffect, useState } from 'react';

import {
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Button from 'components/atoms/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { ChronosDoc, FilterOption } from 'types';

import Pagination from '../../Pagination';
import SearchBox from '../components/SearchBox';
import { useTablePagination } from '../hooks/useTablePagination';
import DocumentsProcessedInfo from './DocumentsProcessedInfo'; // Adjust the import path as necessary
import { filterFns } from './filterFns';
import { HeaderContent } from './HeaderContent';
import useFetchDocs from './hooks/useFetchDocs';
import useGetDocumentColumns from './useGetDocumentColumns';

const initialSortingState = [
  {
    id: 'file_name',
    desc: false,
  },
];

const DocumentEditor = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const [globalFilterInput, setGlobalFilterInput] = useState('');
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>(initialSortingState);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);

  const { pagination, setPagination } = useTablePagination();

  const navigate = useNavigate();

  const columns = useGetDocumentColumns();

  const table = useReactTable<ChronosDoc>({
    columns,
    data: docs,
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    globalFilterFn: filterFns.fuzzyFilter,
    enableMultiSort: true,
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: pagination.pageSize,
      },
    },
  });

  const {
    data: responseDocs,
    refetch: refetchCaseDocs,
    isFetching: isLoadingDocsTable,
  } = useFetchDocs(caseId, pagination.pageIndex + 1, pagination.pageSize, sorting, columnFilters, globalFilter);

  const onSearchCall = async () => {
    // Reset page to 0
    const newPaginationState = { pageIndex: 0, pageSize: pagination.pageSize };
    setPagination(newPaginationState);

    setGlobalFilter(globalFilterInput); // Set the actual filter used for fetching
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetchCaseDocs(); // Trigger the refetch
  };

  const clearSearch = async () => {
    setGlobalFilterInput('');
    setGlobalFilter('');
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetchCaseDocs();
  };

  useEffect(() => {
    refetchCaseDocs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (responseDocs?.docs && JSON.stringify(docs) !== JSON.stringify(responseDocs.docs)) {
      console.log(responseDocs?.docs);
      // setDocs(responseDocs.docs.filter((doc: ChronosDoc) => !doc.is_removed));
      setDocs(responseDocs.docs);
    }
    // eslint-disable-next-line
  }, [responseDocs]);

  const processedCount = responseDocs?.processedCount;
  const rowsToRender = table.getRowModel().rows;
  const isEmptyAfterFiltering = rowsToRender.length === 0;
  console.log('isEmptyAfterFiltering> ', isEmptyAfterFiltering);
  const headersLength = table.getHeaderGroups()[0].headers.length;
  const clearAllFilters = useCallback(() => {
    setColumnFilters([]);
  }, []);

  const handleAddDocuments = useCallback(() => {
    navigate(`/app/chronos/doc-adder/${caseId}`);
  }, [navigate, caseId]);

  const goToPage = (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const totalPages = Math.ceil((responseDocs?.totalDocs || 0) / pagination.pageSize);
  return (
    <>
      <div className="w-full flex flex-row items-center justify-between my-2">
        {caseId && (
          <Button
            type="secondary"
            text="Add Documents"
            onClick={handleAddDocuments}
            size="small"
            className="h-10 bg-[#ECEFFF] text-blue-600 font-semibold px-4 rounded text-sm"
          />
        )}
        <div className="mr-auto"></div>
        <SearchBox
          value={globalFilterInput}
          placeholder={`Search ${responseDocs?.totalDocs || 0} document(s)`}
          onChange={setGlobalFilterInput}
          onSearchCall={onSearchCall}
          clearSearch={clearSearch}
        />
      </div>
      <div className="flex flex-row items-center mb-4 h-14">
        <DocumentsProcessedInfo processedCount={processedCount} totalDocuments={responseDocs?.totalDocs} />
        <div className="ml-auto flex">
          <Pagination
            canGetPrevPage={pagination.pageIndex > 0}
            canGetNextPage={pagination.pageIndex < totalPages - 1}
            prevPage={prevPage}
            nextPage={nextPage}
            goToPage={goToPage}
            currentPage={pagination.pageIndex}
            noOfPages={totalPages}
            isLoadingFacts={isLoadingDocsTable}
          />
        </div>
      </div>
      {isLoadingDocsTable ? (
        <div className="w-full h-24 flex items-center justify-center">
          <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
        </div>
      ) : (
        <div className="w-full flex flex-column overflow-scroll max-h-[calc(100vh-330px)] rounded-xl border border-slate-200">
          {docs && docs.length > 0 && (
            <div className="w-full">
              <table className="w-full">
                <thead className="h-12" style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)' }}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const filterValue = header.column.getFilterValue() as FilterOption[];

                        return (
                          <th
                            key={header.id}
                            className="not-italic font-medium"
                            style={{
                              width: header.column.getSize(),
                              color: 'var(--colors-primary-slate-400, #8897AE)',
                            }}
                          >
                            <HeaderContent
                              header={header}
                              filterValue={filterValue}
                              serverSorting={responseDocs?.appliedSortings || []}
                              serverFilters={responseDocs?.appliedFilters || []}
                              setSorting={setSorting}
                            />
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {isEmptyAfterFiltering ? (
                    <tr>
                      <td colSpan={headersLength}>
                        <div className="flex justify-center items-center flex-col mt-10">
                          <div className="mb-5">No results</div>
                          <div
                            className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                            style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                            onClick={clearAllFilters}
                          >
                            Clear filters
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    rowsToRender.map((row, i) => {
                      return (
                        <tr
                          style={{ background: i % 2 === 0 ? 'white' : '#F9FAFB', borderBottom: '1px solid #c8cad7' }}
                          key={row.id}
                        >
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <td className="pl-3" key={cell.id} id={cell.id} style={{ width: cell.column.getSize() }}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentEditor;
