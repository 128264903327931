import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';
import { MattersResponse } from '../types';

function useMatters(searchQuery: string | null = null, page?: number, pageSize?: number) {
  const { fetchConfigGET } = useGetFetchConfig();

  const queryString = new URLSearchParams({
    search: searchQuery || '',
    ...(page !== undefined && { page: String(page) }),
    ...(pageSize !== undefined && { pageSize: String(pageSize) }),
  }).toString();

  const fetchMatters = async (): Promise<MattersResponse> => {
    const response = await fetch(`${APIBaseChronos}/client/case/matter?${queryString}`, fetchConfigGET);
    if (!response.ok) {
      throw new Error('Fetching matters failed');
    }
    return response.json();
  };

  const {
    isFetching,
    data: responseData,
    refetch,
    error,
  } = useQuery<MattersResponse, Error>(['matters', queryString], fetchMatters, {
    cacheTime: 0,
    enabled: false,
    keepPreviousData: true,
  });

  return {
    isFetching,
    responseData,
    refetch,
    error,
  };
}

export default useMatters;
