import React, { useEffect, useState } from 'react';

import { faAdd, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import { PIPELINE_STATUS } from 'constants/pipelineStatus';
import moment from 'moment';
import { useQuery } from 'react-query';
import Masonry from 'react-responsive-masonry';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosItem } from 'types';

import useGetFetchConfig from '../../../api/useGetFetchConfig';

interface ExpandedTextState {
  [key: string]: boolean;
}

const CasesExplorer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();
  const searchTextQuery = searchParams.get('searchText');
  const catchwordsQuery = searchParams.get('catchwords');
  const dateFromQuery = searchParams.get('dateFrom');
  const dateToQuery = searchParams.get('dateTo');
  const sortByQuery = searchParams.get('sortBy') || 'score_desc';
  const pageQuery = parseInt(searchParams.get('page') || '1') || 1;
  const [expandedText, setExpandedText] = useState<ExpandedTextState>({});
  const advancedFiltersActive = false;
  const [searchText, setSearchText] = useState(searchTextQuery || '');
  const nPages = 1;
  const matterId = searchParams.get('matterId');
  const toggleTextExpansion = (itemId: string) => {
    setExpandedText((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const {
    isFetching: isFetchingCases,
    data: responseData,
    refetch,
  } = useQuery(
    'exploreResult',
    () => {
      const cleanSearchText = searchText?.replace(/\s+/g, ' ').trim() || '';
      return fetch(
        `${APIBaseChronos}/client/case/byMatterId/${matterId}?search=${cleanSearchText}&page=${pageQuery}&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        fetchConfigGET,
      )
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingMatter,
    data: responseMatterData,
    refetch: refetchMatterData,
  } = useQuery(
    'matterDataResult',
    () => {
      return fetch(`${APIBaseChronos}/client/case/matter/${matterId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingNewCase,
    data: responseNewCase,
    refetch: refetchNewCase,
  } = useQuery(
    'newCase',
    () => {
      const fetchConfig = getFetchConfig({
        method: 'POST',
        data: {
          matterId: matterId,
        },
      });
      return fetch(`${APIBaseChronos}/client/case`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  useEffect(() => {
    if (responseMatterData?.type === 'unauthorized') {
      Swal.fire({
        title: '',
        text: 'Unauthorized. You are being redirected to the matters explorer',
        showConfirmButton: false,
        showCancelButton: false,
        timer: 3000,
      });
      navigate(`/app/chronos/matters`);
    }
    // eslint-disable-next-line
  }, [responseMatterData]);

  useEffect(() => {
    setSearchText(searchTextQuery || '');
    refetch();
    refetchMatterData();
    // eslint-disable-next-line
  }, [searchTextQuery]);

  useEffect(() => {
    if (responseNewCase?.newCaseObject?.case_id) {
      navigate(`/app/chronos/case-creator/${responseNewCase?.newCaseObject?.case_id}`);
    }
    // eslint-disable-next-line
  }, [responseNewCase]);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClickCaseItem = (id: string, pipeline_status: string) => {
    if ([PIPELINE_STATUS.draft.value].includes(pipeline_status)) {
      navigate(`/app/chronos/case-creator/${id}`);
    } else {
      navigate(`/app/chronos/case-editor/summary?caseId=${id}`);
    }
  };

  const handleClickAddDocumentsItem = (id: string, pipeline_status: string) => {
    if ([PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(pipeline_status)) {
      navigate(`/app/chronos/doc-adder/${id}`);
    } else {
      navigate(`/app/chronos/case-creator/${id}`);
    }
  };

  const handleGoToPreviousPage = () => {
    if (pageQuery > 1) {
      if (advancedFiltersActive) {
        navigate(
          `/app/explore?searchText=${searchTextQuery}&page=${
            pageQuery - 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/explore?searchText=${searchText}&page=${pageQuery - 1}`);
      }
    }
  };

  const handleGoToNextPage = () => {
    if (pageQuery < nPages) {
      if (advancedFiltersActive) {
        navigate(
          `/app/chronos/explore?searchText=${searchTextQuery}&page=${
            pageQuery + 1
          }&catchwords=${catchwordsQuery}&dateFrom=${dateFromQuery}&dateTo=${dateToQuery}&sortBy=${sortByQuery}`,
        );
      } else {
        navigate(`/app/chronos/explore?searchText=${searchText}&page=${pageQuery + 1}`);
      }
    }
  };

  const goToNewCase = () => {
    refetchNewCase();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  const handleClickDelete = (caseIdToDelete: string) => {
    Swal.fire({
      title: '',
      text: 'Deleting cases is irreversible. Do you want to proceed?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        const fetchConfig = getFetchConfig({ method: 'DELETE' });
        return fetch(`${APIBaseChronos}/client/case/${caseIdToDelete}`, fetchConfig)
          .then((res) => {
            return res.json();
          })
          .then((resJson) => {
            if (resJson.success) {
              Swal.fire({
                title: '',
                text: 'Case deleted successfully',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              refetch();
            } else if (resJson.notCreator) {
              Swal.fire({
                title: 'Not authorized',
                text: 'You are not the creator of this case',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: '',
                text: 'Error deleting case. Try again later',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '',
              text: 'Error deleting case. Try again later',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000,
            });
            console.error(err);
          });
      }
    });
  };

  const handleClickMatters = () => {
    navigate(`/app/chronos/matters`);
  };

  return (
    <div className=" flex flex-col w-full h-screen">
      <div className="LotaGrotesque font-sans flex flex-col md:flex-row items-start justify-start text-white h--full overflow-y-auto">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-row w-full flex-wrap mt-11">
            <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">
              {!isLoadingMatter && (
                <div className="mb-4 w-full pr-10 pl-3 flex flex-col">
                  <div className="text-gray-900 text-4xl not-italic font-semibold mb-4 flex flex-row items-center cursor-pointer ">
                    <span className="text-gray-700 hover:text-gray-800" onClick={handleClickMatters}>
                      Matters
                    </span>
                    <FontAwesomeIcon icon={faChevronRight} className="h-6 mx-4 text-gray-900" />
                    {responseMatterData?.matterDetails?.matter_name}
                  </div>
                  <div
                    className="relative  bg-white mb-4"
                    style={{ width: '549px', borderRadius: '16px', border: '1px solid var(--black-10, #E7E7E7)' }}
                  >
                    <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-5 text-gray-400" />
                    <input
                      className="pl-10 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
                      style={{ borderRadius: '16px', height: '56px' }}
                      onChange={handleChangeSearchInput}
                      onKeyDown={handleKeyDown}
                      value={searchText}
                      placeholder={'Search cases'}
                    />
                  </div>
                  <button
                    className="cursor-pointer flex flex-row justify-center items-center  h-12  px-4 bg-blue-600  hover:bg-blue-500 disabled:bg-gray-500 shrink-0"
                    onClick={goToNewCase}
                    style={{ borderRadius: '16px', width: '164px', height: '56px' }}
                  >
                    {isLoadingNewCase ? (
                      <StageSpinner className="m-auto" size={25} color={'white'} />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faAdd} className="text-white mr-2" />
                        New Case
                      </>
                    )}
                  </button>{' '}
                  {/* {responseData?.items?.length && (
                    <Select
                      options={Object.values(SORT_BY_CHRONOS)}
                      className="outline-none bg-white rounded-md ml-auto w-56"
                      styles={reactSelectStylesExplorer}
                      onChange={handleChangeSortBy}
                      value={sortByOptionFromQuery}
                      placeholder={'Sort by'}
                    />
                  )} */}
                </div>
              )}
              {!isFetchingCases && !isLoadingMatter && !responseData?.items?.length && (
                <div className="text-xl text-gray-900 mb-4 w-full pl-3">No existing cases found</div>
              )}
              {isLoadingMatter && (
                <div className="w-full h-full flex items-center justify-center">
                  <StageSpinner className="m-auto" size={25} color={'#081D57'} />
                </div>
              )}
              <div className="pr-7 w-full flex flex-row flex-wrap">
                {isFetchingCases && (
                  <div className="w-full h-full flex items-center justify-center">
                    <StageSpinner className="m-auto" size={25} color={'#081D57'} />
                  </div>
                )}
                <Masonry columnsCount={2}>
                  {!isFetchingCases &&
                    responseData?.items
                      ?.filter((item: ChronosItem) => !item.is_removed)
                      ?.map((item: ChronosItem, idxItem: number) => {
                        return (
                          <div key={idxItem} className="p-3">
                            <div className="bg-white w-full rounded-lg px-8 pt-8 flex items-start justify-start flex-col">
                              {item.dispute_status && (
                                <div className="flex items-start justify-start">
                                  <div className="font-normal text-xs flex items-center text-blue-600 h-5 bg-indigo-100 rounded mr-3 px-2">
                                    {item.dispute_status}
                                  </div>
                                </div>
                              )}
                              {item.case_name && (
                                <div
                                  className="text-xl not-italic font-semibold mt-3"
                                  onClick={() => handleClickCaseItem(item.case_id, item.pipeline_status)}
                                  style={{ color: 'var(--black-100, #1C1C1C)' }}
                                >
                                  {item.case_name}
                                </div>
                              )}
                              {item.case_legal_issues && (
                                <div
                                  className="text-xl not-italic font-semibold"
                                  onClick={() => handleClickCaseItem(item.case_id, item.pipeline_status)}
                                  style={{ color: 'rgba(28, 28, 28, 0.60)' }}
                                >
                                  {item.case_parties}
                                </div>
                              )}
                              <div className="overflow-hidden text-green-700 text-sm not-italic font-semibold leading-5 right-0 flex justify-between items-center mt-2">
                                Date: {`${moment(item.date_created).format('MMMM DD, YYYY')}`}
                              </div>
                              {item.case_legal_issues && (
                                <div
                                  className={`overflow-hidden text-sm not-italic font-normal leading-4 mt-3 ${
                                    expandedText[item.case_id] ? '' : 'line-clamp-3'
                                  }`}
                                  style={{ color: 'var(--black-40, rgba(0, 0, 0, 0.40))' }}
                                  onClick={() => toggleTextExpansion(item.case_id)}
                                >
                                  {item.case_legal_issues}
                                </div>
                              )}
                              <div className="flex flex-row">
                                <button
                                  className={`exploreButton cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-400 font-semibold text-sm text-white mt-5`}
                                  onClick={() => handleClickCaseItem(item.case_id, item.pipeline_status)}
                                >
                                  View
                                </button>
                                <button
                                  className={`exploreButton cursor-pointer flex justify-center items-center min-w-20 px-4 h-8 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:bg-gray-400 font-semibold text-sm text-white mt-5 ml-2`}
                                  onClick={() => handleClickAddDocumentsItem(item.case_id, item.pipeline_status)}
                                  disabled={
                                    ![
                                      PIPELINE_STATUS.success.value,
                                      PIPELINE_STATUS.complete.value,
                                      PIPELINE_STATUS.draft.value,
                                    ].includes(item.pipeline_status)
                                  }
                                >
                                  Add Documents
                                </button>
                              </div>
                              {item.editor_email && (
                                <div
                                  className="text-sm not-italic font-normal leading-4 flex items-start bg-yellow-200 mt-3"
                                  style={{ color: 'rgba(0, 0, 0, 0.80)', padding: '8px 15px', borderRadius: '40px' }}
                                >
                                  {`Last updated by ${item.editor_email} on ${moment(item.max_date).format(
                                    'DD/MM/YYYY',
                                  )}`}
                                </div>
                              )}
                              <div
                                className="ml-auto cursor-pointer text-xs font-semibold flex items-center rounded-lg text-red-600 bg-red-200 my-3 h-8 px-4"
                                onClick={() => handleClickDelete(item.case_id)}
                              >
                                Delete case
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </Masonry>
              </div>
              {nPages > 1 && (
                <div className="w-full flex flex-row items-center justify-center text-black my-10">
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery <= 1}
                    onClick={handleGoToPreviousPage}
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                  <button className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer">
                    {pageQuery}
                  </button>
                  <button
                    className="bg-white disabled:bg-gray-200 disabled:text-white p-2 rounded-lg mx-4 h-10 w-10 flex items-center justify-center cursor-pointer"
                    disabled={pageQuery >= nPages}
                    onClick={handleGoToNextPage}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesExplorer;
