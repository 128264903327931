import React from 'react';

import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, SortingState, flexRender } from '@tanstack/react-table';
import { ChronosDoc, FilterOption } from 'types';

import MultiselectFilter from '../components/Filters/MultiselectFilter';

const sortingIcons: Record<string, JSX.Element> = {
  asc: (
    <FontAwesomeIcon
      icon={faArrowUp}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
  desc: (
    <FontAwesomeIcon
      icon={faArrowDown}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
};

interface HeaderContentProps {
  header: Header<ChronosDoc, unknown>;
  filterValue: FilterOption[];
  serverSorting: [string, string][];
  serverFilters: [string, string | string[]][];
  setSorting: (newSortingState: SortingState) => void;
}

export const HeaderContent: React.FC<HeaderContentProps> = ({
  header,
  filterValue,
  serverSorting,
  serverFilters,
  setSorting,
}) => {
  const centerItem = ['facts_count', 'verified_facts_count', 'is_processed'].includes(header.id);

  const columnSorting = serverSorting.find(([id]) => id === header.id);
  const sortDirection = columnSorting ? (columnSorting[1] as 'asc' | 'desc') : undefined;

  const customSortClickHandler = () => {
    let newSortDirection: 'asc' | 'desc' | undefined;

    if (!sortDirection) {
      newSortDirection = 'asc';
    } else if (sortDirection === 'asc') {
      newSortDirection = 'desc';
    } else {
      newSortDirection = undefined;
    }

    const newSortingState = serverSorting
      .filter(([id]) => id !== header.id)
      .map(([id, direction]) => ({ id, desc: direction === 'desc' }));

    if (newSortDirection) {
      newSortingState.unshift({ id: header.id, desc: newSortDirection === 'desc' });
    }

    setSorting(newSortingState);
  };

  return (
    <div
      className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-3 cursor-pointer`}
    >
      <div
        onClick={customSortClickHandler}
        className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'}`}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortDirection ? sortingIcons[sortDirection] : null}
      </div>
      {header.column.getCanFilter() && <MultiselectFilter column={header.column} filterValue={filterValue} />}
    </div>
  );
};
