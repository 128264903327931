import React, { useRef, useState, useEffect } from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StageSpinner } from 'react-spinners-kit';

interface ChronologyDropdownProps {
  createChronologyDisabled: boolean;
  isLoadingChronology: boolean;
  handleCreateChronology: () => void;
  handleAddToExistingChronology: () => void;
}

const ChronologyDropdown: React.FC<ChronologyDropdownProps> = ({
  createChronologyDisabled,
  isLoadingChronology,
  handleCreateChronology,
  handleAddToExistingChronology,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        // Need to do this so that the create chronology tour works when clicking next on tour
        let element = event.target as HTMLElement;
        let hasDataAttr = false;
        for (let i = 0; i < 4 && element; i++) {
          // Check up to 3 parents (0 is the target itself)
          if (element.hasAttribute('data-tour-elem')) {
            hasDataAttr = true;
            break;
          }
          element = element.parentElement as HTMLElement;
        }
        if (!hasDataAttr) {
          setDropdownOpen(false);
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const maxHeight = dropdownOpen ? `${dropdownContentRef.current?.scrollHeight}px` : '0px';

  return (
    <div data-open={dropdownOpen} className="relative ml-3" ref={dropdownRef}>
      <button
        id="chronology-dropdown"
        className="text-sm flex justify-center items-center px-2 py-3 rounded not-italic font-semibold w-36 cursor-pointer disabled:cursor-not-allowed disabled:bg-gray-500 disabled:opacity-20"
        style={{
          backgroundColor: '#ECEFFF',
          color: '#4161FF',
        }}
        disabled={createChronologyDisabled}
        onClick={() => !createChronologyDisabled && toggleDropdown()}
      >
        {isLoadingChronology ? <StageSpinner className="m-auto" size={25} color="#4161FF" /> : 'Chronology'}
        <FontAwesomeIcon
          icon={dropdownOpen ? faChevronUp : faChevronDown}
          style={{ color: '#4161FF' }}
          className="ml-2 -mr-2 mt-1"
        />
      </button>

      <div
        id="chronology-dropdown-options"
        ref={dropdownContentRef}
        style={{
          maxHeight,
          overflow: 'hidden',
          transition: 'max-height 0.5s ease',
        }}
        // add border if dropdown is open
        className="text-sm absolute rounded-b-md shadow-lg w-36 z-10 bg-white "
      >
        <button
          className="flex justify-center items-center px-2 py-3 not-italic w-full cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          onClick={handleCreateChronology}
        >
          Create New
        </button>
        <button
          className="flex justify-center items-center px-2 py-3 not-italic w-full cursor-pointer hover:bg-gray-100 focus:bg-gray-100"
          onClick={handleAddToExistingChronology}
        >
          Add to Existing
        </button>
      </div>
    </div>
  );
};

export default ChronologyDropdown;
