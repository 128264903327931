import { faComment, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Copy from 'components/atoms/Copy';
import Accordion from 'components/molecules/Accordian';

import formatSummary from '../../helpers/formatHTML';

const handleCopyClick = (content: string) => {
  const text = content
    .replace(/\[.*?\]/g, '')
    .replace(/<b>/g, '')
    .replace(/<\/b>/g, '')
    .replace(/<br>/g, '\n');
  navigator.clipboard.writeText(text);
};

const ThemeContent = ({
  content,
  subquestions,
  scrollToRow,
  id,
}: {
  content: string;
  subquestions: { title: string; message_content: string }[];
  scrollToRow: (id: string) => void;
  id?: string;
}) => {
  return (
    <div className="mt-2 text-gray-800 text-base leading-relaxed ">
      <div className="pr-2">
        <p id={`${id}-text`}>{formatSummary(content, scrollToRow)}</p>
        <div className="text-gray-800 flex gap-2 mt-4 mb-8">
          <Copy copyHandler={() => handleCopyClick(content)} />
        </div>
      </div>

      {subquestions && subquestions.length > 0 && (
        <>
          <div className="text-lg font-semibold pl-4 mb-4">
            <FontAwesomeIcon icon={faComment} className="text-gray-800 w-5 h-5 cursor-pointer mr-2" />
            Related Questions
          </div>

          <div className="flex flex-col mb-8">
            {subquestions.map((subquestion, index) => (
              <div key={index} id={`${id}-subquestion-${index}`}>
                <Accordion
                  title={<p className="hover:text-blue-500 pr-12 text-sm">{subquestion.title}</p>}
                  content={<SubquestionContent content={subquestion.message_content} scrollToRow={scrollToRow} />}
                  icon={<FontAwesomeIcon icon={faPlus} className="text-blue-500 transition-transform duration-300" />}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const SubquestionContent = ({ content, scrollToRow }: { content: string; scrollToRow: (id: string) => void }) => {
  return (
    <div className="pr-2 mt-2 text-gray-800 text-base leading-relaxed">
      <p>{formatSummary(content, scrollToRow)}</p>
      <div className="flex gap-2 mt-4 mb-6">
        <Copy copyHandler={() => handleCopyClick(content)} />
      </div>
    </div>
  );
};

export default ThemeContent;
