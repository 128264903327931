import React, { useEffect, useState } from 'react';

import { faAdd, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OrgMemberInfo, useAuthInfo } from '@propelauth/react';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useUserContext } from 'Contexts/User';
import Masonry from 'react-responsive-masonry';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import Pagination from '../Pagination';
import MatterItemComponent from './components/MatterItemComponent';
import useMatters from './hooks/useMatters';
import { usePagination } from './hooks/usePagination';
import { MatterItem } from './types';

const roles = {
  ADMIN: 'Admin',
};
const MattersExplorer = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const authInfo = useAuthInfo();
  const orgs = authInfo.orgHelper?.getOrgs();
  const asignedOrg = orgs?.length && (orgs[0] as OrgMemberInfo);
  const assignedRole = asignedOrg && asignedOrg?.userAssignedRole;
  const isAdmin = assignedRole === roles['ADMIN'];
  const { user } = useUserContext();
  const isSuperAdmin = user?.metadata?.orgSuperAdmin === 'true';
  const { getFetchConfig } = useGetFetchConfig();
  const searchTextQuery = searchParams.get('searchText') || '';
  const [searchText, setSearchText] = useState(searchTextQuery);

  const { pagination, setPagination } = usePagination();

  // Use the useMatters hook with pagination
  const { isFetching, responseData, refetch } = useMatters(searchText, pagination.pageIndex, pagination.pageSize); // Page size is set to 10

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  const handleChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClickMatterItem = (id: string) => {
    navigate(`/app/chronos/explore?matterId=${id}`);
  };

  const handleClickEditMatter = (id: string) => {
    navigate(`/app/chronos/matter-editor/${id}`);
  };

  const goToPage = async (val: number) => {
    const newPaginationState = { pageIndex: val, pageSize: pagination.pageSize };
    setPagination(newPaginationState);
    await new Promise((resolve) => setTimeout(resolve, 0));
    refetch();
  };

  const prevPage = () => goToPage(pagination.pageIndex - 1);
  const nextPage = () => goToPage(pagination.pageIndex + 1);

  const totalPages = responseData ? Math.ceil((parseInt(responseData.totalCount) || 0) / pagination.pageSize) : 0;

  const goToNewMatter = () => {
    navigate(`/app/chronos/matter-creator`);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch();
    }
  };

  const handleClickDelete = (matterIdToDelete: string) => {
    Swal.fire({
      title: '',
      text: 'Deleting matters is irreversible. Do you want to proceed?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        const fetchConfig = getFetchConfig({ method: 'DELETE' });
        return fetch(`${APIBaseChronos}/client/matter/${matterIdToDelete}`, fetchConfig)
          .then((res) => {
            return res.json();
          })
          .then((resJson) => {
            if (resJson.success) {
              Swal.fire({
                title: '',
                text: 'Matter deleted successfully',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
              refetch();
            } else if (resJson.notCreator) {
              Swal.fire({
                title: 'Not authorized',
                text: 'You are not the creator of this matter or you do not have the require permissions',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: '',
                text: 'Error deleting the matter. Try again later',
                showConfirmButton: false,
                showCancelButton: false,
                timer: 3000,
              });
            }
          })
          .catch((err) => {
            Swal.fire({
              title: '',
              text: 'Error deleting the matter. Try again later',
              showConfirmButton: false,
              showCancelButton: false,
              timer: 3000,
            });
            console.error(err);
          });
      }
    });
  };

  return (
    <div className=" flex flex-col w-full h-screen">
      <div className="LotaGrotesque font-sans flex flex-col md:flex-row items-start justify-start text-white h--full overflow-y-auto">
        <div className="flex flex-col items-start justify-start w-full h-full">
          <div className="flex flex-row w-full flex-wrap mt-11">
            <div className="flex flex-wrap flex-row items-start justify-start w-full pl-4">
              <div className="mb-4 w-full pr-10 pl-3 flex flex-col">
                <div className="text-gray-900 text-4xl not-italic font-semibold mb-4">Matters</div>
                <div className="flex flex-row text-black">
                  <div
                    className="relative  bg-white mr-auto rounded-2xl w-full border border-gray-300"
                    style={{ width: '549px' }}
                  >
                    <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-5 text-gray-400" />
                    {
                      <input
                        className="pl-10 w-full focus:outline-none not-italic font-normal text-base text-black placeholder:text-gray-400 bg-white"
                        style={{ borderRadius: '16px', height: '56px' }}
                        onChange={handleChangeSearchInput}
                        onKeyDown={handleKeyDown}
                        value={searchText}
                        placeholder={'Search matters'}
                      />
                    }
                  </div>
                  <Pagination
                    canGetPrevPage={pagination.pageIndex > 0}
                    canGetNextPage={pagination.pageIndex < totalPages - 1}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    goToPage={goToPage}
                    currentPage={pagination.pageIndex}
                    noOfPages={totalPages}
                    isLoadingFacts={isFetching}
                  />
                </div>
                {(isAdmin || isSuperAdmin) && (
                  <button
                    className="cursor-pointer flex flex-row justify-center items-center  h-12 mt-4  px-4 bg-blue-600  hover:bg-blue-500 disabled:bg-gray-500 shrink-0"
                    onClick={goToNewMatter}
                    style={{ borderRadius: '16px', width: '164px', height: '56px' }}
                  >
                    <FontAwesomeIcon icon={faAdd} className="text-white mr-2" />
                    New matter
                  </button>
                )}
              </div>
              {!isFetching && !responseData?.items?.length && (
                <div className="text-xl text-gray-900 mb-4 w-full px-10 ml-0.5">No items found</div>
              )}

              {isFetching && (
                <div className="w-full flex items-center justify-center">
                  <StageSpinner className="m-auto" size={25} color={'#081D57'} />
                </div>
              )}
              <div className="pr-7 w-full flex flex-row flex-wrap">
                <Masonry columnsCount={2}>
                  {!isFetching &&
                    responseData?.items?.map((item: MatterItem, idxItem: number) => {
                      const isCreator = item.created_by === authInfo.user?.userId;
                      return (
                        <div key={idxItem} className="p-3">
                          <MatterItemComponent
                            item={item}
                            onEdit={handleClickEditMatter}
                            onDelete={handleClickDelete}
                            onView={handleClickMatterItem}
                            isCreator={isCreator}
                            isSuperAdmin={isSuperAdmin}
                          />
                        </div>
                      );
                    })}
                </Masonry>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MattersExplorer;
