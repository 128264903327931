import { useCallback, useEffect, useRef } from 'react';

import { faArrowDown, faArrowUp, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ColumnSizingInstance,
  Header,
  HeadersInstance,
  RowModel,
  SortDirection,
  SortingState,
  flexRender,
} from '@tanstack/react-table';
import { StageSpinner } from 'react-spinners-kit';

import { ChronosFact, FilterOption, ChronosDoc } from '../../../../../types';
import { FILTER_OPTIONS } from '../../components/Filters//filterOptions';
import MultiselectFilter from '../../components/Filters/MultiselectFilter';
import MultiselectFilterDocs from '../../components/Filters/MultiselectFilterDocs';
import IncludeAllDropdown from '../components/IncludeAllDropdown';

type FilterOptionsKey = keyof typeof FILTER_OPTIONS;

export const hiddenColumns = ['date_of_subject', 'document_date'];

const sortingIcons: Record<SortDirection, JSX.Element> = {
  asc: (
    <FontAwesomeIcon
      icon={faArrowUp}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
  desc: (
    <FontAwesomeIcon
      icon={faArrowDown}
      className="text-gray-700 mx-2"
      style={{ color: 'var(--colors-primary-slate-400, #8897AE)' }}
    />
  ),
};

const resizerStyles = {
  default: 'absolute right-0 top-0 h-3/5 bg-blue-200 w-0.5 cursor-col-resize select-none touch-none mt-2',
  isResizing: 'bg-blue',
};

interface HeaderContentProps {
  header: Header<ChronosFact, unknown>;
  allDocuments: ChronosDoc[];
  handleToggleAll: (checked: boolean) => void;
  allRowsChecked: boolean;
  anyRowsChecked: boolean;
  serverSorting: [string, string][];
  setSorting: (newSortingState: SortingState) => void;
  serverFilters: [string, string | string[]][];
}

const HeaderContent = function GenerateColumnHeader({
  header,
  allDocuments,
  handleToggleAll,
  allRowsChecked,
  anyRowsChecked,
  serverSorting,
  setSorting,
  serverFilters,
}: HeaderContentProps) {
  const centerItem = ['included', 'verify', 'important'].includes(header.id);
  const columnSorting = serverSorting.find(([id]) => id === header.id);
  const sortDirection = columnSorting ? (columnSorting[1] as SortDirection) : undefined;

  const uniqueDocumentsMap = allDocuments.reduce<Record<string, ChronosDoc>>((acc, doc) => {
    acc[doc.doc_id] = doc;
    return acc;
  }, {});

  const uniqueDocuments = Object.values(uniqueDocumentsMap);

  const documentsOptions = uniqueDocuments.map((document) => ({
    value: document.doc_id,
    label: document.file_name.split('.').filter(Boolean)[0],
  }));

  const filter = serverFilters.find(([id]) => id === header.id);
  const filterValues: FilterOption[] = filter
    ? (Array.isArray(filter[1]) ? filter[1] : [filter[1]]).map((value) => {
        const stringValue = value.toString();
        const option = (
          header.id === 'source_doc'
            ? documentsOptions
            : (FILTER_OPTIONS[header.id as FilterOptionsKey] as FilterOption[])
        ).find((option) => option.value.toString() === stringValue);
        return option || { value: stringValue, label: stringValue };
      })
    : [];

  const customSortClickHandler = () => {
    let newSortDirection: SortDirection | undefined;

    if (header.id !== 'source_doc') {
      if (!sortDirection) {
        newSortDirection = 'asc';
      } else if (sortDirection === 'asc') {
        newSortDirection = 'desc';
      } else {
        newSortDirection = undefined;
      }

      const newSortingState = serverSorting
        .filter(([id]) => id !== header.id)
        .map(([id, direction]) => ({ id, desc: direction === 'desc' }));

      if (newSortDirection) {
        newSortingState.unshift({ id: header.id, desc: newSortDirection === 'desc' });
      }

      setSorting(newSortingState);
    }
  };

  return (
    <div
      className={`flex flex-row items-center ${centerItem ? 'justify-center' : 'justify-start'} pl-2 pr-2 ${
        header.column.getCanSort() ? 'cursor-pointer' : ''
      }`}
    >
      <div className="flex flex-row items-center" onClick={customSortClickHandler}>
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortDirection ? sortingIcons[sortDirection] : null}
      </div>

      {header.column.id === 'included' && (
        <div>
          <IncludeAllDropdown
            allRowsChecked={allRowsChecked}
            anyRowsChecked={anyRowsChecked}
            handleSelectAll={() => handleToggleAll(true)}
            handleClearAll={() => handleToggleAll(false)}
          />
        </div>
      )}

      {header.column.getCanFilter() && header.column.id !== 'source_doc' && (
        <MultiselectFilter column={header.column} filterValue={filterValues} />
      )}
      {header.column.getCanFilter() && header.column.id === 'source_doc' && (
        <MultiselectFilterDocs column={header.column} filterValue={filterValues} allDocuments={allDocuments} />
      )}
    </div>
  );
};

interface FactsEditorTableProps {
  isLoadingFacts: boolean;
  clearAllFilters: () => void;
  getHeaderGroups: HeadersInstance<ChronosFact>['getHeaderGroups'];
  getCenterTotalSize: ColumnSizingInstance['getCenterTotalSize'];
  getRowModel: () => RowModel<ChronosFact>;
  handleToggleAll: HeaderContentProps['handleToggleAll'];
  allRowsChecked: boolean;
  anyRowsChecked: boolean;
  hasActiveFilters: boolean;
  serverSorting: [string, string][];
  serverFilters: [string, string | string[]][];
  setSorting: (newSortingState: SortingState) => void;
  keyFactIds: Set<string> | undefined;
  allDocuments: ChronosDoc[];
}

const FactsEditorTable = ({
  getRowModel,
  clearAllFilters,
  isLoadingFacts,
  getHeaderGroups,
  getCenterTotalSize,
  handleToggleAll,
  allRowsChecked,
  anyRowsChecked,
  hasActiveFilters,
  serverSorting,
  setSorting,
  serverFilters,
  keyFactIds,
  allDocuments,
}: FactsEditorTableProps) => {
  // Check if we have keyFactId int the URL
  const urlParams = new URLSearchParams(window.location.search);
  const keyFactId = urlParams.get('keyFactId');
  const factsTableRef = useRef<HTMLDivElement>(null);

  const scrollToRow = (id: string) => {
    const element = document.getElementById(id);
    if (element && factsTableRef.current) {
      const headerHeight = document.querySelector('thead')?.clientHeight || 0;
      const rowOffset = element.offsetTop - headerHeight;

      factsTableRef.current.scrollTo({
        top: rowOffset,
        behavior: 'smooth',
      });
      element.classList.add('relative', 'z-30', 'animate-pulse-border-gold');
      setTimeout(() => {
        element.classList.remove('animate-pulse-border-gold', 'z-30', 'relative');
        // Remove the keyFactId from the URL
        urlParams.delete('keyFactId');
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
      }, 2000); // How long we pulse for
    }
  };

  useEffect(() => {
    // Wait a little bit after isLoadingFacts is false to scroll to the row
    if (!isLoadingFacts && keyFactId) {
      setTimeout(() => {
        scrollToRow(keyFactId);
      }, 300);
    }
    // eslint-disable-next-line
  }, [keyFactId, isLoadingFacts]);

  const rowsToRender = getRowModel().rows;
  const isEmptyAfterFiltering = rowsToRender.length === 0;

  const clearAllFiltersMemoized = useCallback(() => {
    clearAllFilters();
  }, [clearAllFilters]);

  if (isLoadingFacts) {
    return (
      <div className="w-full h-24 flex items-center justify-center">
        <StageSpinner className="m-auto" color={'#4161FF'} />
      </div>
    );
  }

  const headersLength = getHeaderGroups()[0].headers.length - hiddenColumns.length;

  return (
    <div
      ref={factsTableRef}
      className="max-h-[calc(100vh-330px)] max-w-full overflow-auto border border-gray-200 rounded-xl"
    >
      <div style={{ width: getCenterTotalSize(), minWidth: '100%' }}>
        <table style={{ minWidth: '100%' }}>
          <thead
            className="h-12"
            style={{ background: 'var(--colors-primary-slate-25, #F9FAFB)', position: 'sticky', top: '0' }}
          >
            {getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const cellStyle = hiddenColumns.includes(header.id) ? { display: 'none' } : {};
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          ...cellStyle,
                          color: 'var(--colors-primary-slate-400, #8897AE)',
                          width: header.getSize(),
                        }}
                        className="not-italic font-medium leading-5 relative"
                      >
                        <HeaderContent
                          serverFilters={serverFilters}
                          header={header}
                          allDocuments={allDocuments}
                          handleToggleAll={handleToggleAll}
                          allRowsChecked={allRowsChecked}
                          anyRowsChecked={anyRowsChecked}
                          serverSorting={serverSorting}
                          setSorting={setSorting}
                        />
                        <div
                          {...{
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            className: `${resizerStyles.default} ${
                              header.column.getIsResizing() && resizerStyles.isResizing
                            }`,
                          }}
                        />
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {isEmptyAfterFiltering && !isLoadingFacts ? (
              <tr>
                <td colSpan={headersLength}>
                  <div className="flex justify-center items-center flex-col my-10">
                    <div className="mb-5">No results</div>
                    {hasActiveFilters && (
                      <button
                        className="flex justify-center items-center px-2 py-3 rounded-lg   not-italic font-bold  w-56 cursor-pointer"
                        style={{ backgroundColor: '#ECEFFF', color: '#4161FF' }}
                        onClick={clearAllFiltersMemoized}
                      >
                        Clear filters
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ) : (
              rowsToRender.map((row, rowIndex) => {
                return (
                  <tr
                    id={row.id}
                    key={row.id}
                    style={{
                      background: row.index % 2 === 0 ? 'white' : '#F9FAFB',
                      height: '100px',
                      borderBottom: '1px solid #c8cad7',
                    }}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => {
                      const cellStyle = hiddenColumns.includes(cell.column.id) ? { display: 'none' } : {};
                      return (
                        <td
                          {...{
                            key: cell.id,
                            style: {
                              ...cellStyle,
                              width: cell.column.getSize(),
                              height: '100px',
                              minHeight: '100px',
                              maxHeight: '100px',
                            },
                          }}
                        >
                          <div className="flex h-full w-full">
                            {cellIndex === 0 && keyFactIds && keyFactIds.has(row.id) ? (
                              <div className="h-full px-4 bg-yellow-100 flex items-center border border-l-0 border-r-1 border-y-0 border-yellow-500">
                                <FontAwesomeIcon icon={faStar} className="text-yellow-500" />
                              </div>
                            ) : null}
                            <div
                              id={`${rowIndex === 0 && cellIndex === 0 && 'include-selector-tour'}`}
                              className="pl-3 flex items-center w-full justify-center"
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FactsEditorTable;
