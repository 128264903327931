import Lottie, { Options } from 'react-lottie';

interface Props {
  width: number;
  height: number;
  animationData: any;
  isPaused?: boolean;
  autoplay?: boolean;
  loop?: boolean;
  isStopped?: boolean;
}

const LottieAnimation = ({
  animationData,
  width,
  height,
  isPaused = false,
  autoplay = true,
  loop = true,
  isStopped = false,
}: Props) => {
  const DEFAULT_OPTIONS: Options = {
    loop: loop,
    autoplay: autoplay,
    animationData: animationData,
  };

  return <Lottie isPaused={isPaused} options={DEFAULT_OPTIONS} height={height} width={width} isStopped={isStopped} />;
};

export default LottieAnimation;
