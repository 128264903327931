import { useCallback, useEffect, useState } from 'react';

import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate, faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from '@tanstack/react-table';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Button from 'components/atoms/Button';
import ExpandableButton from 'components/molecules/ExpandableButton';
import { SEARCH_STEPS } from 'constants/tours';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Tour from 'reactour';
import Swal from 'sweetalert2';
import { CheckState, ChronosChronology, ChronosDoc, ChronosFact } from 'types';
import SearchBox from '../../components/SearchBox';
import ShowHideColumns from '../../ShowHideColumns';
import AuditTrail from '../AuditTrail';
import ChronologyDropdown from '../components/ChronologyDropdown';
import FactAdder from '../FactAdder';
import { AuditTrailData } from '../hooks/useAuditTrailData';
import { factColumnLabels } from '../useGetFactColumns';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
};

interface FactsEditorToolbarProps {
  caseId: string | null;
  docId?: string;
  globalFilter: string;
  resultsLength: number;
  resultsIncludedLength: number;
  facts: ChronosFact[];
  checkedRows: Record<string, CheckState>;
  isDateDirty: boolean;
  refetchCaseFacts: () => void;
  clearSearch: () => void;
  resetVisibleColumns: () => void;
  setGlobalFilter: (value: string) => void;
  columns: Column<ChronosFact>[];
  canGetPrevPage: boolean;
  canGetNextPage: boolean;
  prevPage: () => void;
  nextPage: () => void;
  currentPage: number;
  noOfPages: number;
  isLoadingFacts: boolean;
  onSearchCall: () => void;
  isLoading: boolean;
  goToPage: (value: number) => void;
  keyFactFilterActive: boolean;
  handleKeyFactFilterToggle: () => void;
  auditTrailData: AuditTrailData[];
  auditTrailCurrentPage: number;
  auditTrailTotalPages: number;
  goToPrevAuditTrailPage: () => void;
  goToNextAuditTrailPage: () => void;
  isFetchingAuditTrail: boolean;
  refetchAuditTrail: () => void;
  resetAuditTrailPage: () => void;
  setAuditTrailPage: (x: number) => void;
  auditTrailPage: number;
}

const FactsEditorToolbar = ({
  docId,
  caseId,
  facts,
  columns,
  globalFilter,
  refetchCaseFacts,
  clearSearch,
  setGlobalFilter,
  isLoadingFacts,
  resetVisibleColumns,
  resultsLength,
  resultsIncludedLength,
  canGetPrevPage,
  canGetNextPage,
  prevPage,
  nextPage,
  currentPage,
  noOfPages,
  isDateDirty,
  onSearchCall,
  isLoading,
  goToPage,
  keyFactFilterActive,
  handleKeyFactFilterToggle,
  auditTrailData,
  auditTrailCurrentPage,
  auditTrailTotalPages,
  goToPrevAuditTrailPage,
  goToNextAuditTrailPage,
  isFetchingAuditTrail,
  refetchAuditTrail,
  resetAuditTrailPage,
  setAuditTrailPage,
  auditTrailPage,
}: FactsEditorToolbarProps) => {
  // State
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectChronologyModalIsOpen, setSelectChronologyModalIsOpen] = useState(false);
  const [isLoadingChronology, setIsLoadingChronology] = useState(false);
  const [chronologies, setChronologies] = useState<ChronosChronology[]>([]);
  const [docs, setDocs] = useState<ChronosDoc[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [exploreTourOpen, setExploreTourOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  const { data: responseDocs, refetch: refetchCaseDocs } = useQuery(['userDocs', docId], fetchCaseDocs, {
    enabled: false,
    cacheTime: 0,
  });

  function fetchCaseDocs() {
    return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => res.json());
  }

  // Effects
  useEffect(() => {
    if (responseDocs?.docs && responseDocs.docs.length > 0) {
      setDocs(responseDocs.docs);
    }
  }, [responseDocs]);

  useEffect(() => {
    refetchCaseDocs();
  }, [docId, refetchCaseDocs]);

  const handleNewFactCreated = useCallback(() => {
    refetchCaseFacts();
    closeModal();
    // eslint-disable-next-line
  }, [refetchCaseFacts]);

  useEffect(() => {
    if (currentPage !== parseInt(currentPageString) - 1) {
      setCurrentPageString((currentPage + 1).toString());
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    // Check if explore = true is in seach params
    if (searchParams.get('explore') === 'true') {
      setExploreTourOpen(true);

      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete('explore');
      // Update the URL without the 'explore' parameter
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  // Handlers
  const closeModal = useCallback(() => {
    setModalIsOpen(false);
  }, []);

  const { isFetching: isLoadingChronologies, refetch: refetchChronologies } = useQuery(
    ['fetchChronologies', caseId],
    fetchChronologies,
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  async function fetchChronologies() {
    const response = await fetch(
      `${APIBaseChronos}/client/case/chronology/byCaseId/${caseId}?search=${''}`,
      fetchConfigGET,
    );
    const data = await response.json();
    setChronologies(data.chronologies);
  }

  async function startChronology(chronologyId: string | null) {
    setIsLoadingChronology(true);
    const fetchConfig = getFetchConfig({
      method: 'POST',
      data: {
        caseId,
        runId: facts[0].run_id,
        add_to_existing: !!chronologyId,
        existing_chronology_id: chronologyId,
      },
    });

    try {
      const res = await fetch(`${APIBaseChronos}/client/case/chronology`, fetchConfig);
      const responseJson = await res.json();
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('chronologyId', responseJson.lambdaResponse.chronology_id);
      searchParams.set('page', '1');
      navigate('/app/chronos/case-editor/chronology?' + searchParams.toString(), { replace: true });
    } catch (err) {
      console.error('Fetch Error: ', err);
      Swal.fire({
        title: 'Error while creating the chronology',
        text: 'Please try again later',
        showConfirmButton: false,
        timer: 3000,
      });
    } finally {
      setIsLoadingChronology(false);
    }
  }

  const handleCreateChronology = () => {
    startChronology(null);
  };

  const handleAddToExistingChronology = () => {
    setSelectChronologyModalIsOpen(true);
    refetchChronologies();
  };

  const handleSelectChronology = (chronologyId: string) => {
    closeSelectChronologyModal();
    startChronology(chronologyId);
  };

  const closeSelectChronologyModal = useCallback(() => {
    setSelectChronologyModalIsOpen(false);
  }, []);

  const [currentPageString, setCurrentPageString] = useState('1');

  const handleChangePage = (value: string) => {
    setCurrentPageString(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const pageNumber = parseInt(currentPageString) - 1;
      goToPage && goToPage(pageNumber);
    }
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Add Fact">
        <FactAdder docs={docs} handleNewFactCreated={handleNewFactCreated} docId={docId} />
      </Modal>

      <Modal
        style={customStyles}
        isOpen={selectChronologyModalIsOpen}
        onRequestClose={closeSelectChronologyModal}
        contentLabel="Select Chronology"
      >
        <div className="p-4 overflow-auto flex items-center justify-start flex-col h-96 overflow-y-scroll">
          <h2 className="text-xl font-bold mb-4">Select Existing Chronology</h2>
          {isLoadingChronologies ? (
            <StageSpinner className="m-auto" color={'#4161FF'} size={25} />
          ) : (
            <div className="w-full">
              {chronologies.map((chronology) => (
                <button
                  key={chronology.chronology_id}
                  className="flex justify-center items-center px-2 py-3 rounded-lg not-italic w-full font-bold cursor-pointer ml-auto mt-2"
                  style={{
                    backgroundColor: '#ECEFFF',
                    color: '#4161FF',
                  }}
                  onClick={() => handleSelectChronology(chronology.chronology_id)}
                >
                  {chronology.latest_title}
                </button>
              ))}
            </div>
          )}
        </div>
      </Modal>
      <div className="mb-2 mt-2 flex flex-row items-center justify-between">
        <div className="flex">
          <Button size="small" type="secondary" onClick={() => setModalIsOpen(true)} text="Add Fact" />

          {!docId && (
            <ChronologyDropdown
              createChronologyDisabled={resultsIncludedLength === 0}
              isLoadingChronology={isLoadingChronology}
              handleCreateChronology={handleCreateChronology}
              handleAddToExistingChronology={handleAddToExistingChronology}
            />
          )}
        </div>
        <div className="ml-auto"></div>
        <div id="facts-search-bar">
          <SearchBox
            value={globalFilter}
            placeholder={`Search ${resultsLength} facts`}
            onChange={setGlobalFilter}
            onSearchCall={onSearchCall}
            clearSearch={clearSearch}
          />
        </div>
      </div>
      {!docId && (
        <div className="flex mb-4 items-center justify-between h-14">
          <div className="">
            <p className="text-gray-800 font-semibold">
              <b className="text-lg">{resultsLength}</b>
              {resultsLength > 1 ? ' facts' : ' fact'} shown
            </p>
            <p className="text-gray-800 font-semibold">
              <b className="text-lg">{resultsIncludedLength}</b> {resultsIncludedLength > 1 ? ' facts' : ' fact'}{' '}
              included in the chronology
            </p>
          </div>

          <div className="flex gap-6">
            <div className="flex gap-2 my-1">
              {isDateDirty && (
                <div className="flex justify-center items-center transition-transform transform scale-0 animate-popIn">
                  <ExpandableButton
                    icon={faArrowsRotate}
                    onClick={refetchCaseFacts}
                    className="py-2 hover:bg-gray-100 flex items-center rounded border text-blue-600 transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center"
                  >
                    <p className="text-sm">Refresh Table</p>
                  </ExpandableButton>
                </div>
              )}

              <ExpandableButton
                isActive={keyFactFilterActive}
                icon={keyFactFilterActive ? faStar : farStar}
                onClick={handleKeyFactFilterToggle}
                className={`${
                  keyFactFilterActive ? 'text-yellow-500' : 'text-blue-600'
                } py-2 hover:bg-gray-100 flex items-center rounded border text-blue-600 transition-all duration-300 ease-in-out overflow-hidden w-10 justify-center`}
              >
                <p className="text-sm">Key Facts</p>
              </ExpandableButton>

              <ShowHideColumns
                columns={columns}
                resetVisibleColumns={resetVisibleColumns}
                columnLabels={factColumnLabels}
              />
              <AuditTrail
                auditTrailData={auditTrailData}
                currentPage={auditTrailCurrentPage}
                totalPages={auditTrailTotalPages}
                goToPrevPage={goToPrevAuditTrailPage}
                goToNextPage={goToNextAuditTrailPage}
                isFetchingAuditTrail={isFetchingAuditTrail}
                refetchAuditTrail={refetchAuditTrail}
                resetAuditTrailPage={resetAuditTrailPage}
                setAuditTrailPage={setAuditTrailPage}
                auditTrailPage={auditTrailPage}
                goToPage={goToPage}
              />
            </div>

            <div className="flex items-center justify-between">
              <button
                style={{ backgroundColor: '#ECEFFF' }}
                className={`px-1 py-1 rounded mr-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
                onClick={prevPage}
                disabled={isLoadingFacts || !canGetPrevPage}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-gray-700 mx-2"
                  style={{
                    color: !isLoadingFacts && canGetPrevPage ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)',
                  }}
                />
              </button>
              {isLoadingFacts ? (
                <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
              ) : (
                <p className="text-center flex flex-row items-center justify-center ">
                  Page{' '}
                  <input
                    value={currentPageString}
                    className="mx-1 px-1 h-8 w-14"
                    onChange={(e) => handleChangePage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    inputMode="numeric"
                    min="1"
                    style={{
                      borderRadius: '5px',
                      border: '1px solid var(--black-10, #E7E7E7)',
                    }}
                  />
                  of {noOfPages}
                </p>
              )}
              <button
                style={{ backgroundColor: '#ECEFFF' }}
                className={`px-1 py-1 rounded ml-3 cursor-pointer disabled:cursor-not-allowed hover:opacity-80`}
                onClick={nextPage}
                disabled={isLoadingFacts || !canGetNextPage}
              >
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-gray-700 mx-2"
                  style={{
                    color: !isLoadingFacts && canGetNextPage ? '#4161FF' : 'var(--colors-primary-slate-400, #8897AE)',
                  }}
                />
              </button>
            </div>
          </div>
          <Tour
            startAt={0}
            steps={SEARCH_STEPS}
            isOpen={exploreTourOpen}
            onRequestClose={() => setExploreTourOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default FactsEditorToolbar;
