import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import PageMissing from 'components/molecules/PageMissing';
import { PIPELINE_FREEZE_LOAD_STATUS, PIPELINE_PROCESSING_STATUS, PIPELINE_STATUS } from 'constants/pipelineStatus';
import 'moment-timezone';
import Modal from 'react-modal';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { ChronosRun } from 'types';

import CaseSummary from './CaseSummary';
import ChronologyEditor from './ChronologyEditor';
import NavigationTabs from './components/editor/NavigationTabs';
import StatusDisplay from './components/editor/StatusDisplay';
import PipelineCompleteOverview from './components/PipelineCompleteOverview';
import DocumentsEditor from './DocumentsEditor';
import DocViewer from './DocViewer';
import FactsEditor from './FactsEditor';
import computeCaseMessage from './helpers/computeCaseMessage';
import { customStylesModal } from './styles';
import useGetFetchConfig from '../../../api/useGetFetchConfig';

export interface CaseState {
  caseName: string;
  runs: ChronosRun[];
  casePipelineStatus: string;
  queueEstimateDate: Date;
  inQueue: boolean;
  queuePosition: any; // Change this to the actual type
  matterId: string;
  estimateDate: Date;
  modalIsOpen: boolean;
  runStatusIsOpen: boolean;
}

const CaseEditor: React.FC = () => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');
  const docId = searchParams.get('docId');
  const eventId = searchParams.get('eventId');
  const pageNumber = Number(searchParams.get('pageNumber'));
  const location = useLocation();
  const [caseState, setCaseState] = useState<CaseState>({
    caseName: '',
    runs: [],
    casePipelineStatus: '',
    queueEstimateDate: new Date(),
    inQueue: false,
    queuePosition: null,
    matterId: '',
    estimateDate: new Date(),
    modalIsOpen: false,
    runStatusIsOpen: false,
  });
  const navigate = useNavigate();
  const { fetchConfigGET } = useGetFetchConfig();

  const pageRef = useRef<HTMLDivElement>(null);

  const { isFetching: isLoadingCase, refetch: refetchCase } = useQuery(
    'currentCase',
    () => fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET).then((res) => res.json()),
    {
      cacheTime: 0,
      enabled: !PIPELINE_FREEZE_LOAD_STATUS.includes(caseState.casePipelineStatus),
      refetchInterval: 3000,
      onSuccess: (response) => {
        if (response?.type === 'unauthorized') {
          Swal.fire({
            title: '',
            text: 'Unauthorized. You are being redirected to the matters explorer',
            showConfirmButton: false,
            timer: 3000,
          });
          navigate(`/app/chronos/matters`);
        } else if (response?.caseObject?.case_id) {
          setCaseState((prevState) => ({
            ...prevState,
            caseName: response.caseObject.case_name,
            runs: response.runs,
            casePipelineStatus: response.runs[0].pipeline_status,
            queueEstimateDate: response.runs[0].queue_estimate_datetime,
            inQueue: response.runs[0].in_queue,
            queuePosition: response.runs[0].queue_position || null,
            estimateDate: response.runs[0].time_estimate_datetime,
            matterId: response.caseObject.matter_id,
          }));
        }
      },
    },
  );

  useEffect(() => {
    refetchCase();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (docId) {
      setCaseState((prev) => ({ ...prev, modalIsOpen: true }));
    }
  }, [docId, eventId]);

  const chronologyId = searchParams.get('chronologyId');

  const closeModal = useCallback(() => {
    if (chronologyId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('caseId', caseId || '');
      searchParams.delete('docId');
      navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    } else {
      setCaseState((prev) => ({ ...prev, modalIsOpen: false }));
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('caseId', caseId || '');
      searchParams.delete('eventId');
      searchParams.delete('docId');
      searchParams.delete('pageNumber');
      navigate(location.pathname + '?' + searchParams.toString(), { replace: true });
    }
    // eslint-disable-next-line
  }, [navigate, chronologyId]);

  const handleClickBack = () => {
    navigate(`/app/chronos/explore?matterId=${caseState.matterId}`);
  };

  const message = useMemo(
    () =>
      computeCaseMessage(
        caseState.inQueue,
        caseState.queuePosition,
        caseState.estimateDate,
        caseState.queueEstimateDate,
      ),
    [caseState.inQueue, caseState.queuePosition, caseState.estimateDate, caseState.queueEstimateDate],
  );

  const handleClickStatusView = () => {
    setCaseState((prev) => ({ ...prev, runStatusIsOpen: !prev.runStatusIsOpen }));
  };

  const runsInProgress = caseState.runs.filter((run: ChronosRun) => {
    return ![PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(run.pipeline_status);
  });

  return (
    <>
      {docId && (
        <Modal
          isOpen={caseState.modalIsOpen}
          onRequestClose={closeModal}
          style={customStylesModal}
          contentLabel="Document viewer"
        >
          <DocViewer docId={docId} caseId={caseId} eventId={eventId} pageNumber={pageNumber} />
        </Modal>
      )}
      <div ref={pageRef} className="flex flex-col items-start justify-start w-full h-full text-black overflow-y-scroll">
        <div className="w-full h-full pt-8 bg-center pb-2 pr-8 pl-8">
          {/* Still processing case */}
          {PIPELINE_PROCESSING_STATUS.includes(caseState.casePipelineStatus) ||
          caseState.casePipelineStatus === PIPELINE_STATUS.failed.value ? (
            <>
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer"
                onClick={handleClickBack}
              />
              <StatusDisplay casePipelineStatus={caseState.casePipelineStatus} message={message} />
            </>
          ) : isLoadingCase ? (
            <div className="w-full flex flex-col items-center justify-center h-full">
              {/* Loading Case */}
              <StageSpinner className="m-auto" size={40} color={'#4161FF'} />
            </div>
          ) : [PIPELINE_STATUS.success.value, PIPELINE_STATUS.complete.value].includes(caseState.casePipelineStatus) ? (
            <div className="w-full h-full">
              {/* Case loaded  and pipeline successful*/}
              <div className="flex items-center flex-col">
                <div className="w-full flex flex-row justify-between">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    className="mr-3 text-black w-6 h-6 cursor-pointer"
                    onClick={() => handleClickBack()}
                  />
                  <PipelineCompleteOverview
                    handleClickStatusView={handleClickStatusView}
                    caseState={caseState}
                    totalRuns={runsInProgress.length}
                  />
                </div>
                <p className="w-full focus:outline-none text-black text-2xl not-italic font-bold placeholder:text-gray-400 rounded-lg">
                  {caseState.caseName}
                </p>
              </div>
              {caseId && <NavigationTabs caseId={caseId} />}
              <Routes>
                <Route path="summary" element={<CaseSummary outerRef={pageRef} />} />
                <Route path="documents" element={<DocumentsEditor />} />
                <Route path="facts" element={<FactsEditor />} />
                <Route path="chronology" element={<ChronologyEditor />} />
                <Route path="*" element={<Navigate to={`/app/chronos/case-editor/summary`} />} />
              </Routes>
            </div>
          ) : (
            //Add generic message here for unable to load, use same one as the case summary one
            <PageMissing
              title={'Case Loading Failed'}
              description={
                <div className="text-sm mt-4">
                  Sorry! We encounterd a problem loading this case. Please{' '}
                  <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
                    contact
                  </a>{' '}
                  Wexler for support.
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CaseEditor;
