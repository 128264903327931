import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthInfo } from '@propelauth/react';
import { APIBaseChronos } from 'api/hosts';
import FileUploader from 'components/molecules/FileUploader';
import { DISPUTE_STATUS_OPTIONS } from 'constants/disputeStatusOptions';
import { reactSelectStylesCaseCreator } from 'constants/styles';
// import { TIMEZONE_OPTIONS } from 'constants/timezoneOptions';
import { niceBytes } from 'helpers/bytesCalculator';
import { getFileNameWithExtension } from 'helpers/filenames';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { ChronosDoc, MyOptionType } from 'types';

import useGetFetchConfig from '../../../api/useGetFetchConfig';
import StatusIndictor from '../StatusIndicator';

class UploadError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

const CaseCreator: React.FC = () => {
  const navigate = useNavigate();
  const [successfulUploads, setSuccessfulUploads] = useState<number>(0);
  const [expectedUploads, setExpectedUploads] = useState<number>(0);
  const [posting, setPosting] = useState(false);
  const [caseName, setCaseName] = useState<string>('');
  const [caseType, setCaseType] = useState<string>('');
  const [caseIssues, setCaseIssues] = useState<string>('');
  const [caseParties, setCaseParties] = useState<string>('');
  const [docRemoving, setDocRemoving] = useState<string>('');
  const [disputeStatus, setDisputeStatus] = useState<MyOptionType | null>();
  const [docsTab, setDocsTab] = useState<string>('active');
  const { caseId } = useParams();

  const authInfo = useAuthInfo();
  const accessToken = authInfo.accessToken;
  const { fetchConfigGET, getFetchConfig } = useGetFetchConfig();

  // Define refs for each textarea
  const textAreaNameRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaTypeRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaIssuesRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaPartiesRef = useRef<HTMLTextAreaElement | null>(null);

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = '56px';
      if (ref.current.scrollHeight > 56) {
        ref.current.style.height = `${ref.current.scrollHeight}px`;
      }
    }
  };

  const {
    isFetching: isLoadingCase,
    data: responseCase,
    refetch: refetchGetCase,
  } = useQuery(
    ['getCase', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfigGET)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    isFetching: isLoadingUpdateCase,
    data: responseUpdateCase,
    refetch: refetchUpdateCase,
  } = useQuery(
    ['updateCase', caseId],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus: disputeStatus?.value,
          // isDateAmerican: timeZone?.value === 'American' ? true : false,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const { refetch: refetchPreSaveCase } = useQuery(
    ['preSave', caseId],
    () => {
      const fetchConfig = getFetchConfig({
        method: 'PUT',
        data: {
          caseName,
          caseType,
          caseIssues,
          caseParties,
          disputeStatus: disputeStatus?.value,
          // isDateAmerican: timeZone?.value === 'American' ? true : false,
        },
      });
      return fetch(`${APIBaseChronos}/client/case/presave/${caseId}`, fetchConfig)
        .then((res) => {
          return res.json();
        })
        .catch((err) => {});
    },
    {
      cacheTime: 0,
      enabled: false,
    },
  );

  const {
    data: responseDocs,
    refetch: refetchCaseDocs,
    isLoading: isLoadingDocuments,
  } = useQuery(
    ['userDocs', caseId],
    () => {
      return fetch(`${APIBaseChronos}/client/case/doc/${caseId}`, fetchConfigGET).then((res) => {
        return res.json();
      });
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (caseId) {
      refetchGetCase();
      refetchCaseDocs();
    }
    // eslint-disable-next-line
  }, [caseId]);

  useEffect(() => {
    if (responseUpdateCase?.updateDocObject?.case_id) {
      navigate(`/app/chronos/case-editor/summary?caseId=${caseId}`);
    }
    // eslint-disable-next-line
  }, [responseUpdateCase]);

  // Assuming accessToken and other dependencies are stable and don't change on every render
  const uploadFile = useCallback(
    async (file: File) => {
      if (!caseId) return;

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'zip' || fileExtension === 'rar') {
        Swal.fire({
          title: 'Error',
          text: `The file ${file.name} is a ${fileExtension.toUpperCase()} file, which is not supported.`,
          showConfirmButton: false,
          timer: 3000,
        });
        // throw new UploadError('Unsupported file type', new Response());
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('caseId', caseId);

      const accessToken = localStorage.getItem('accessToken');

      const fetchConfig = getFetchConfig({ method: 'POST', data: formData, isFormData: true });

      const endpoint = `client/case/doc`;

      try {
        const response = await fetch(`${APIBaseChronos}/${endpoint}`, {
          ...fetchConfig,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          if (errorData.type === 'zero-byte-file') {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} is empty`,
              showConfirmButton: false,
              timer: 3000,
            });
          } else if (errorData.type === 'upload-failure') {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
          }
          throw new UploadError(errorData.message || 'Error occurred while uploading file', response);
        }
      } catch (error) {
        console.log('error >>>>', error);
        throw error;
      }
    },
    // eslint-disable-next-line
    [caseId, getFetchConfig, APIBaseChronos],
  );

  const isUploadError = (error: unknown): error is UploadError => {
    return error instanceof UploadError;
  };

  const isErrorWithMessage = (error: unknown): error is { message: string } => {
    return (
      typeof error === 'object' && error !== null && 'message' in error && typeof (error as any).message === 'string'
    );
  };

  const uploadWithRetry = async (file: File, maxRetries: number = 8): Promise<void> => {
    let attempt = 0;
    while (attempt <= maxRetries) {
      try {
        await uploadFile(file);
        setSuccessfulUploads((prev) => prev + 1);
        break;
      } catch (error) {
        console.log(error);
        if (isUploadError(error)) {
          const errorData = await error.response.json();
          console.log(errorData);
          if (attempt >= maxRetries || errorData.type === 'upload-failure') {
            Swal.fire({
              title: 'Error',
              text: `The file ${file.name} could not be uploaded. Please try again later 😞`,
              showConfirmButton: false,
              timer: 3000,
            });
            throw error; // We're rethrowing the error here so it can be caught in Promise.allSettled
          } else {
            const delay = Math.pow(2, attempt) * 1000; // Exponential backoff
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
        } else if (isErrorWithMessage(error)) {
          // Handle unexpected errors
          Swal.fire({
            title: 'Error',
            text: `An unexpected error occurred: ${error.message}`,
            showConfirmButton: false,
            timer: 3000,
          });
          throw error;
        } else {
          // Handle unknown errors without a message
          Swal.fire({
            title: 'Error',
            text: `An unexpected error occurred.`,
            showConfirmButton: false,
            timer: 3000,
          });
          throw error;
        }
        attempt++;
      }
    }
  };

  const processInBatches = async (files: File[], batchSize: number = 5): Promise<void> => {
    setPosting(true);
    setSuccessfulUploads(0);
    setExpectedUploads(files.length);

    const batches: File[][] = [];
    while (files.length) {
      batches.push(files.splice(0, batchSize));
    }

    for (const batch of batches) {
      await Promise.allSettled(batch.map((file) => uploadWithRetry(file)));
      // Here you can handle responses, update the UI, etc.
    }

    setPosting(false);
    refetchCaseDocs(); // Uncomment this if you have this function for updating the UI or any other post-upload logic
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      processInBatches(acceptedFiles);
    },
    // eslint-disable-next-line
    [caseId, accessToken, APIBaseChronos], // Add dependencies here if they'll be used inside the useCallback
  );

  const handleRemoveDoc = async (docId: string) => {
    setDocRemoving(docId);
    const fetchConfig = getFetchConfig({ method: 'DELETE' });

    await fetch(`${APIBaseChronos}/client/case/doc/${docId}`, fetchConfig);
    setDocRemoving('');
    refetchCaseDocs();
  };

  const handleChangeCaseName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseName(e.target.value);
    autoResize(textAreaNameRef);
  };

  const handleChangeCaseType = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseType(e.target.value);
    autoResize(textAreaTypeRef);
  };
  const handleChangeCaseIssues = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseIssues(e.target.value);
    autoResize(textAreaIssuesRef);
  };

  const handleChangeCaseParties = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCaseParties(e.target.value);
    autoResize(textAreaPartiesRef);
  };
  const handleUpdateCase = () => {
    refetchUpdateCase();
  };

  useEffect(() => {
    if (responseCase?.caseObject) {
      setCaseName(responseCase?.caseObject?.case_name);
      setCaseIssues(responseCase?.caseObject?.case_legal_issues);
      setCaseParties(responseCase?.caseObject?.case_parties);
      setDisputeStatus(
        DISPUTE_STATUS_OPTIONS[responseCase?.caseObject?.dispute_status as keyof typeof DISPUTE_STATUS_OPTIONS],
      );
      // setTimeZone(TIMEZONE_OPTIONS[responseCase?.caseObject?.timezone as keyof typeof TIMEZONE_OPTIONS]);
      setCaseType(responseCase?.caseObject?.case_type);
    }
  }, [responseCase]);

  const handleClickBack = () => {
    Swal.fire({
      title: '',
      text: 'Are you sure you want to stop creating the case?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Proceed',
      cancelButtonText: 'Cancel',
      timer: 10000,
      confirmButtonColor: 'rgb(37 99 235)',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        refetchPreSaveCase();
        navigate(`/app/chronos/explore?matterId=${responseCase?.caseObject?.matter_id}`);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const handleChangeDisputeStatus = (val: MyOptionType) => {
    setDisputeStatus(val);
  };

  useEffect(() => {
    // Function to save accessToken to local storage
    const saveAccessToken = () => {
      localStorage.setItem('accessToken', accessToken || '');
    };

    // Save immediately when component mounts
    saveAccessToken();

    // Start an interval to save accessToken every second
    const intervalId = setInterval(saveAccessToken, 1000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, [accessToken]);

  // const handleChangeTimeZone = (val: MyOptionType) => {
  //   setTimeZone(val);
  // };

  const docs = useMemo(() => {
    return responseDocs?.docs || [];
  }, [responseDocs?.docs]);

  // Splitting docs into active and removed
  const activeDocs = useMemo(() => docs.filter((doc: ChronosDoc) => !doc.is_removed), [docs]);
  const removedDocs = useMemo(() => docs.filter((doc: ChronosDoc) => doc.is_removed), [docs]);

  interface ExtendedChronosDoc extends ChronosDoc {
    isDuplicate?: boolean;
    isUnsupported?: boolean; // New property to mark unsupported files
  }

  const markDuplicatesAndUnsupported = (
    docsArray: ChronosDoc[],
    groupedByHash: Record<string, ChronosDoc[]>,
  ): ExtendedChronosDoc[] => {
    return docsArray.map((doc) => ({
      ...doc,
      isDuplicate: groupedByHash[doc.file_hash]?.length > 1,
      isUnsupported: ['zip', 'rar'].includes(doc?.file_extension?.toLowerCase() || ''),
    }));
  };

  const groupDocsByHash = (docsArray: ChronosDoc[]): Record<string, ChronosDoc[]> => {
    return docsArray.reduce<Record<string, ChronosDoc[]>>((acc, doc) => {
      const key = doc.file_hash || 'undefined'; // Handle undefined file_hash appropriately
      (acc[key] = acc[key] || []).push(doc);
      return acc;
    }, {});
  };

  const sortDocs = (docsArray: ChronosDoc[]): ChronosDoc[] => {
    const groupedByHash = groupDocsByHash(docsArray);
    const markedDocs = markDuplicatesAndUnsupported(docsArray, groupedByHash);

    return markedDocs.sort((a, b) => {
      if (a.isDuplicate && !b.isDuplicate) return -1;
      if (!a.isDuplicate && b.isDuplicate) return 1;
      if (a.isUnsupported && !b.isUnsupported) return -1;
      if (!a.isUnsupported && b.isUnsupported) return 1;
      if (!a.file_hash && b.file_hash) return 1;
      if (a.file_hash && !b.file_hash) return -1;
      return a.file_hash ? a.file_hash.localeCompare(b.file_hash || '') : 0;
    });
  };

  // Sorted and grouped active documents
  // eslint-disable-next-line
  const sortedActiveDocs = useMemo(() => sortDocs(activeDocs), [activeDocs]);
  const groupedActiveDocs = useMemo(() => groupDocsByHash(sortedActiveDocs), [sortedActiveDocs]);

  // Sorted and grouped removed documents
  // eslint-disable-next-line
  const sortedRemovedDocs = useMemo(() => sortDocs(removedDocs), [removedDocs]);
  const groupedRemovedDocs = useMemo(() => groupDocsByHash(sortedRemovedDocs), [sortedRemovedDocs]);

  const duplicatesActive = Object.values(groupedActiveDocs).filter((group: any) => group.length > 1);
  const duplicatesRemoved = Object.values(groupedRemovedDocs).filter((group: any) => group.length > 1);

  const showActive = () => {
    setDocsTab('active');
  };

  const showRemoved = () => {
    setDocsTab('removed');
  };

  const MB_150_IN_BYTES = 150 * 1e6;
  const sum = activeDocs.reduce((acc: any, doc: any) => acc + (doc?.file_size || 0), 0) || 0;
  const prohibited_docs_length = activeDocs.filter((doc: any) => {
    return doc?.file_size > MB_150_IN_BYTES;
  }).length;
  const at_least_one_prohibited = prohibited_docs_length >= 1;
  const only_1_prohibited = prohibited_docs_length === 1;
  const GB_10_IN_BYTES = 10 * 1e9;
  const size_limit_exceeded = sum > GB_10_IN_BYTES;
  const count_limit_exceeded = activeDocs.length > 5000;
  const unsupported_docs = sortedActiveDocs.filter((doc: any) => doc.isUnsupported);

  const message = prohibited_docs_length
    ? `${prohibited_docs_length} file${only_1_prohibited ? '' : 's'} exceed${
        only_1_prohibited ? 's' : ''
      } 150MB file size limit, please remove to proceed`
    : size_limit_exceeded && count_limit_exceeded
    ? 'Exceeded 10GB total files size and 5,000 file count limits.'
    : size_limit_exceeded
    ? 'The total files size limit of 10GB is exceeded.'
    : count_limit_exceeded
    ? 'The number of files limit is 5,000.'
    : unsupported_docs.length > 0
    ? `${unsupported_docs.length} unsupported file${
        unsupported_docs.length === 1 ? '' : 's'
      } found. Please remove them to proceed.`
    : '';

  return (
    <div className="LotaGrotesque flex flex-row relative overflow-auto w-full text-white">
      <div className="font-sans flex flex-col pl-8 w-full items-start justify-start">
        {isLoadingCase ? (
          <div className="w-full h-full flex flex-col items-center justify-start text-black pt-14">
            <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
          </div>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="mr-3 text-black w-6 h-6 mb-4 cursor-pointer mt-6"
              onClick={() => handleClickBack()}
            />
            <div className="text-black text-3xl not-italic font-bold w-full">New Case</div>
            <div className="" style={{ width: '690px' }}>
              <div className="relative mt-4 flex items-center mr-8">
                <textarea
                  ref={textAreaNameRef}
                  id="caseIssuesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                    border: '1px solid var(--black-10, #E7E7E7)',
                  }}
                  onChange={handleChangeCaseName}
                  value={caseName}
                  placeholder={''}
                  onInput={handleChangeCaseName}
                />

                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseName ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Name of case
                </label>
                <div className="ml-2">
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-name-case"
                    icon={faInfoCircle}
                    data-tooltip-content={`Field used for organisational purposes, does not impact Wexler’s output`}
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-name-case"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>

              <div className="relative mt-4 flex items-center mr-8">
                <textarea
                  ref={textAreaTypeRef}
                  id="caseTypeInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseType}
                  value={caseType}
                  placeholder={''}
                  onInput={handleChangeCaseType}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseType ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  What is the type of case?
                </label>
                <div className="ml-2">
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-type-case"
                    icon={faInfoCircle}
                    data-tooltip-content={`Field used for organisational purposes, does not impact Wexler’s output`}
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-type-case"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>

              <div className="relative mt-4 flex items-center mr-8">
                <textarea
                  ref={textAreaPartiesRef}
                  id="casePartiesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseParties}
                  value={caseParties}
                  placeholder={''}
                  onInput={handleChangeCaseParties}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseParties ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Who are the parties in the case?
                </label>
                <div className="ml-2">
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-parties"
                    icon={faInfoCircle}
                    data-tooltip-content={`Field used for organisational purposes, does not impact Wexler’s output`}
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-parties"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>

              <div className="relative mt-4 flex items-center mr-8">
                <textarea
                  ref={textAreaIssuesRef}
                  id="caseIssuesInput"
                  className="pl-3 w-full focus:outline-none not-italic font-normal text-base text-black rounded-2xl bg-white resize-none"
                  style={{
                    backgroundColor: 'rgb(238, 238, 238, 0.5)',
                    overflow: 'hidden',
                    border: '1px solid var(--black-10, #E7E7E7)',
                    outline: 'none',
                    lineHeight: '26px',
                    height: '66px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    verticalAlign: 'middle',
                    zIndex: 2,
                  }}
                  onChange={handleChangeCaseIssues}
                  value={caseIssues}
                  placeholder={''}
                  onInput={handleChangeCaseIssues}
                />
                <label
                  style={{ color: 'var(--primary-beta-grey-35, #000000)', zIndex: 1 }}
                  htmlFor="caseIssuesInput"
                  className={`absolute left-3 top-5 transition-all duration-300 ${
                    caseIssues ? 'text-xs transform -translate-y-4' : 'text-base'
                  }`}
                >
                  Provide a short summary of the factual issues in either list format or prose
                </label>
                <div className="ml-2">
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-2"
                    icon={faInfoCircle}
                    data-tooltip-content="Please refer to the Case Synopsis page for how to draft this field. This determines which facts Wexler considers important among those extracted and also the significance explanation for each"
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-2"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="relative  flex items-center mr-8">
                  <Select
                    options={Object.values(DISPUTE_STATUS_OPTIONS)}
                    className={`outline-none rounded-md mr-2 w-full`}
                    styles={reactSelectStylesCaseCreator}
                    onChange={handleChangeDisputeStatus}
                    value={disputeStatus}
                    placeholder={'Dispute status'}
                  />
                  <FontAwesomeIcon
                    data-tooltip-id="tooltip-name-case"
                    icon={faInfoCircle}
                    data-tooltip-content={`Field used for organisational purposes, does not impact Wexler’s output`}
                    className=" text-gray-700 ml-2 text-sm cursor-pointer"
                    style={{ color: 'var(--colors-primary-slate-400, #8897AE)', width: '16px' }}
                  />
                  <Tooltip
                    id="tooltip-name-case"
                    className="z-10"
                    style={{
                      display: 'flex',
                      width: '300px',
                      padding: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      borderRadius: '16px',
                      background: '#FFF',
                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                      color: 'var(--colors-primary-slate-600, #455468)',
                      fontFamily: 'Lota Grotesque',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                    }}
                  />
                </div>
              </div>

              {/* <Select
                options={Object.values(TIMEZONE_OPTIONS)}
                className={`outline-none bg-white rounded-md mr-8`}
                styles={reactSelectStylesCaseCreator}
                onChange={handleChangeTimeZone}
                value={timeZone}
                placeholder={'Are dates American or European?'}
              /> */}
              <div className="text-black text-3xl not-italic font-bold mt-4 flex flex-row">
                <div className="mr-3"> Files {sortedActiveDocs?.length ? `(${sortedActiveDocs?.length})` : ''} </div>
                {(isLoadingDocuments || posting) && <StageSpinner className="m-auto" size={25} color={'#4161FF'} />}
              </div>
              {!!message && <div className="text-red-700 font-semibold italic">{message}</div>}
              <FileUploader
                uploading={posting}
                onDrop={onDrop}
                expectedUploads={expectedUploads}
                successfulUploads={successfulUploads}
              />
              <div className="w-full flex flex-column">
                {isLoadingDocuments && <StageSpinner className="m-auto" size={25} color={'#4161FF'} />}
                {responseDocs?.docs && responseDocs?.docs.length > 0 && (
                  <div className="w-full mr-8">
                    <div className="flex flex-row mb-3">
                      <div
                        className={`px-6 py-3   ${
                          docsTab === 'active' ? 'border-b-4 text-blue-600 border-blue-600' : 'text-gray-600'
                        } cursor-pointer`}
                        onClick={showActive}
                      >
                        Active
                      </div>
                      <div
                        className={`px-6 py-3   ${
                          docsTab === 'removed' ? 'border-b-4 text-blue-600 border-blue-600' : 'text-gray-600'
                        } cursor-pointer`}
                        onClick={showRemoved}
                      >
                        Removed
                      </div>
                    </div>
                    {(docsTab === 'active' ? sortedActiveDocs : sortedRemovedDocs)?.map((doc: any, idx: number) => {
                      const file_size_exceeded = doc.file_size > MB_150_IN_BYTES;
                      const isDuplicate = (docsTab === 'active' ? duplicatesActive : duplicatesRemoved).some(
                        (group: any) => {
                          if (!doc.file_hash) return false;
                          return group.some((dupDoc: any) => dupDoc.file_hash === doc.file_hash);
                        },
                      );
                      const isUnsupported = doc.isUnsupported;
                      // Apply a orange border if it's a duplicate
                      const docStyle = isDuplicate
                        ? { border: '2px solid orange' }
                        : isUnsupported
                        ? { border: '2px solid red' }
                        : {};
                      return (
                        <div
                          className={`bg-white mb-3 p-3 w-full rounded-lg h-20 flex items-center ${
                            file_size_exceeded ? ' border border-red-500' : ''
                          } ${isDuplicate ? ' border border-orange-500' : ''} ${
                            isUnsupported ? 'border border-red-500' : ''
                          }`}
                          key={doc._id}
                          style={docStyle}
                        >
                          <div
                            className="w-full flex items-center justify-between"
                            style={{ color: 'var(--dark-blue-80, #373D68)' }}
                          >
                            <div
                              className={`w-1/3 shrink-0 truncate ${
                                file_size_exceeded ? ' text-red-700 font-semibold italic' : ''
                              } ${isDuplicate ? '  text-orange-700 font-semibold italic' : ''} ${
                                isUnsupported ? ' text-red-700 font-semibold italic' : ''
                              }`}
                            >
                              {getFileNameWithExtension(doc?.file_name)}
                            </div>
                            <div className="w-1/3 shrink-0 flex items-center justify-center">
                              <div
                                className={`flex w-13 h-7 justify-center items-center py-3 rounded-3xl text-sm not-italic font-bold leading-4 uppercase px-2 bg-blue-50 text-blue-400 ${
                                  file_size_exceeded ? 'text-red-600 bg-red-200' : ''
                                }  ${isDuplicate ? '  text-orange-600 bg-orange-200' : ''} ${
                                  isUnsupported ? ' text-red-600 bg-red-200' : ''
                                }`}
                              >
                                {doc.file_extension ? doc.file_extension.toUpperCase() : '---'}
                              </div>
                            </div>
                            <div
                              className={`text-sm not-italic font-medium w-22 flex flex-row items-center justify-center ${
                                file_size_exceeded ? 'text-red-700 font-semibold italic' : ''
                              }  ${isDuplicate ? ' text-orange-700 font-semibold italic' : ''} ${
                                isUnsupported ? ' text-red-700 font-semibold italic' : ''
                              }`}
                            >
                              {file_size_exceeded && !isDuplicate && (
                                <>
                                  <FontAwesomeIcon
                                    data-tooltip-id={`tooltip-exceeded-file-size-${idx}`}
                                    icon={faInfoCircle}
                                    data-tooltip-content="File size too large for processing"
                                    className=" text-red-700 ml-2 text-sm cursor-pointer mr-3"
                                    style={{ width: '16px' }}
                                  />
                                  <Tooltip
                                    id={`tooltip-exceeded-file-size-${idx}`}
                                    className="z-10"
                                    style={{
                                      display: 'flex',
                                      width: '300px',
                                      padding: '16px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '8px',
                                      borderRadius: '16px',
                                      background: '#FFF',
                                      boxShadow: '0px 12px 20px 0px rgba(0, 0, 0, 0.05)',
                                      color: 'var(--colors-primary-slate-600, #455468)',
                                      fontFamily: 'Lota Grotesque',
                                      fontSize: '12px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '20px',
                                      letterSpacing: '-0.3px',
                                    }}
                                  />
                                </>
                              )}
                              {isDuplicate && 'Duplicate'}
                              {isUnsupported && 'Unsupported'}
                              {!isDuplicate &&
                                !isUnsupported &&
                                (doc.file_size ? `${niceBytes(doc.file_size)}` : '---')}
                            </div>
                            {docsTab === 'active' ? (
                              <div
                                className={`flex justify-center items-center px-2 py-3 rounded-lg ml-auto w-24 not-italic shrink-0 font-bold leading-6 cursor-pointer bg-blue-100 text-blue-600 ${
                                  file_size_exceeded ? 'text-red-600 bg-red-200' : ''
                                }  ${isDuplicate ? ' text-orange-700 bg-orange-200 font-semibold italic' : ''} ${
                                  isUnsupported ? ' text-red-600 bg-red-200' : ''
                                }`}
                                onClick={() => handleRemoveDoc(doc.doc_id)}
                              >
                                {docRemoving === doc.doc_id ? (
                                  <StageSpinner className="m-auto" size={25} color={'#4161FF'} />
                                ) : (
                                  'Remove'
                                )}
                              </div>
                            ) : (
                              <div className="w-24"></div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {!isLoadingCase && (
        <div className="w-full flex flex-row h-full sticky top-0 ml-7">
          <StatusIndictor />
          <button
            style={{ bottom: '10px' }}
            className="absolute cursor-pointer flex flex-row justify-center items-center  h-12 w-96 bg-blue-600 rounded-lg hover:bg-blue-500 disabled:opacity-20 shrink-0 text-center text-base not-italic font-bold leading-6 text-white disabled:cursor-not-allowed px-6 py-4"
            onClick={handleUpdateCase}
            disabled={
              !!duplicatesActive.length ||
              !!at_least_one_prohibited ||
              !!only_1_prohibited ||
              isLoadingUpdateCase ||
              !caseName ||
              !caseType ||
              !caseIssues ||
              !caseParties ||
              !disputeStatus ||
              // !timeZone ||
              posting ||
              !sortedActiveDocs?.length ||
              size_limit_exceeded ||
              count_limit_exceeded ||
              !!unsupported_docs.length // Disable the button if there are unsupported docs
            }
          >
            {isLoadingUpdateCase ? <StageSpinner className="m-auto" color={'white'} /> : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CaseCreator;
