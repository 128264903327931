import React, { useState, useEffect, useRef } from 'react';

import {
  faHashtag,
  faFile,
  faMagnifyingGlass,
  faTimeline,
  faChartSimple,
  faArrowUpRightFromSquare,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import Copy from 'components/atoms/Copy';
import Accordion from 'components/molecules/Accordian';
import Modal from 'components/molecules/Modal';
import PageMissing from 'components/molecules/PageMissing';
import { THEME_STEPS } from 'constants/tours';
import { useSearchParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import { Tooltip } from 'react-tooltip';
import Tour from 'reactour';
import { ChronosFact } from 'types';

import ActionButton from './components/ActionButton';
import ChronologyModal from './components/ChronologyModal';
import FactsTable from './components/KeyFactsTable';
import RelevantDocs from './components/RelevantDocs';
import StatsTable from './components/StatsTable';
import ThemeContent from './components/ThemeContent';
import formatSummary from './helpers/formatHTML';
import useGetCaseOverview from './hooks/useGetCaseOverview';
import useGetFactColumns from './hooks/useGetFactColumns';
import useGetSummary from './hooks/useGetSummary';

const CaseSummary = ({ outerRef }: any) => {
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get('caseId');

  // State
  const keyFactsTableRef = useRef<HTMLDivElement>(null);
  const [chronologyExplorerOpen, setChronologyExplorerOpen] = useState(false);
  const [themeTitles, setThemeTitles] = useState<{ title: string; message_id: string }[]>([]);
  const [themeText, setThemeText] = useState<string[]>([]);
  const [summaryText, setSummaryText] = useState<string>('');
  const [themeSubquestions, setThemeSubQuestions] = useState<{
    [message_id: string]: { title: string; message_content: string }[];
  }>({});
  const [themeTourOpen, setThemeTourOpen] = useState(false);

  // Hooks
  const { data: summaries, isLoading: summaryIsLoading, refetch: refetchSummary } = useGetSummary(caseId);
  const { data: overview, isLoading: overviewIsLoading, refetch: refetchOverview } = useGetCaseOverview(caseId);
  const { fetchConfigGET } = useGetFetchConfig();
  const keyFactColumns = useGetFactColumns();
  const location = useLocation();
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    refetchSummary();
    refetchOverview();
  }, [caseId, refetchSummary, refetchOverview]);

  useEffect(() => {
    if (!summaries) return;

    const rawThemes = summaries.summaries.filter((summary: any) => summary.title.toLowerCase() !== 'overview');

    const themeTitles = rawThemes.map((summary: any) => ({
      title: summary.title,
      message_id: summary.message_id,
    }));

    const themeTexts = rawThemes.map((summary: any) =>
      summary.message_content
        .replace(/<b>.*?<\/b>/g, '') // Remove anything between <b> tags
        .replace(/<br>/g, ' ') // Replace all <br> tags with a space
        .trim(),
    );

    setThemeTitles(themeTitles);
    setThemeText(themeTexts);
    setThemeSubQuestions(summaries.subQuestions);

    const overviewSummary = summaries.summaries.find((summary: any) => summary.title.toLowerCase() === 'overview');

    if (overviewSummary) {
      const overviewText = overviewSummary.message_content
        .replace(/<b>.*?<\/b>/g, '')
        .replace(/<br>/g, ' ')
        .trim();
      setSummaryText(overviewText);
    }
  }, [summaries]);

  // Handlers
  const scrollToRow = (id: string) => {
    const element = document.getElementById(id);

    if (element && keyFactsTableRef.current && outerRef.current) {
      // First scroll to the table
      const afterTable = document.getElementById('after-key-facts-table');
      outerRef.current.scrollTo({
        top: afterTable?.offsetTop || 1800,
        behavior: 'smooth',
      });

      // After a delay to allow the table to scroll into view, scroll to the row
      setTimeout(() => {
        if (keyFactsTableRef.current) {
          // elementOffset is the distance from the top of the table to the top of the row
          const rowOffset = element.offsetTop;

          keyFactsTableRef.current.scrollTo({
            top: rowOffset - 48,
            behavior: 'smooth',
          });
          element.classList.add('relative', 'z-30', 'animate-pulse-border-blue');
          setTimeout(() => {
            element.classList.remove('animate-pulse-border-blue', 'z-30', 'relative');
          }, 2000); // How long we pulse for
        }
      }, 400); // Delay to scroll to table
    }
  };

  const handleViewKeyFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');

    const factIds = summaries?.keyFacts.map((fact: ChronosFact) => fact.event_id).join(',');
    searchParams.set('keyFactIds', factIds || '');

    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleViewFacts = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleViewDocs = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/documents?' + searchParams.toString());
  };

  const handleViewSingleFact = async (factId: string) => {
    // Work out what page that fact is going to be on
    const resp = await fetch(`${APIBaseChronos}/client/case/fact/${caseId}/pageNumber/${factId}`, fetchConfigGET);
    const data = await resp.json();
    const rowNumber = data.rowNumber;
    //TODO Change this to a config page number
    const page = Math.ceil(rowNumber / 50) - 1;

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', page.toString());
    searchParams.set('keyFactId', factId);
    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  const handleRelevantDocClick = (docId: string) => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('docId', docId);
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/summary?' + searchParams.toString());
  };

  const handleCopyClick = (content: string) => {
    const text = content
      .replace(/\[.*?\]/g, '')
      .replace(/<b>/g, '')
      .replace(/<\/b>/g, '')
      .replace(/<br>/g, '\n');
    navigator.clipboard.writeText(text);
  };

  const handleExploreClicked = () => {
    const searchParams = new URLSearchParams(location.search);
    const caseId = searchParams.get('caseId');
    if (!caseId) return;
    searchParams.set('caseId', caseId);
    searchParams.set('explore', 'true');
    searchParams.set('page', '0');
    navigate('/app/chronos/case-editor/facts?' + searchParams.toString());
  };

  return (
    <div className="flex flex-col pb-4 leading-relaxed">
      {summaryIsLoading || overviewIsLoading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner className="m-auto" color={'rgb(37 99 235)'} />
        </div>
      ) : (!overview || !summaries) && !summaryIsLoading && !overviewIsLoading ? (
        <PageMissing
          title="Summary Not Found"
          description={
            <div className="text-sm mt-4">
              Sorry! We couldn't find a summary for this case. Please{' '}
              <a className="text-blue-500 hover:cursor-pointer" href="mailto:support@wexler.ai">
                contact
              </a>{' '}
              Wexler for support.
            </div>
          }
        />
      ) : (
        <>
          {themeTitles.length && themeTitles.length && (
            <>
              <div className="flex mb-8">
                <div className="rounded-md bg-gray-50 border-2 border-gray-200 border-opacity-60 p-4">
                  <div className="text-lg font-semibold mb-2 flex items-center">
                    Summary
                    <FontAwesomeIcon
                      data-tooltip-id="summary"
                      data-tooltip-content="Wexler analyses the extracted facts to create an executive overview of the case, focusing on the key issues highlighted in the synopsis. This summary is directional and should not be considered comprehensive or exhaustive."
                      icon={faInfoCircle}
                      className="text-gray-400 w-3 h-3 cursor-pointer ml-2"
                    />
                    <Tooltip
                      id="summary"
                      opacity={1}
                      style={{
                        width: '400px',
                        textAlign: 'center',
                        padding: '8px',
                        borderRadius: '8px',
                        backgroundColor: 'white',
                        color: 'black',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        letterSpacing: '-0.3px',
                        zIndex: 1,
                      }}
                    />
                  </div>
                  <div className="">{formatSummary(summaryText, scrollToRow)}</div>
                  <div className="text-gray-800 flex gap-2 mt-4">
                    <Copy copyHandler={() => handleCopyClick(summaryText)} />
                  </div>
                </div>
              </div>

              <div className="flex justify-between w-full gap-8 mb-10">
                <div className="relative bg-gray-50 p-4 border-2 border-gray-200 border-opacity-40 rounded-lg py-4 w-5/12">
                  <h2 className=" title-font font-medium text-lg mb-2 border-2 border-x-0 border-t-0 pb-1">Explore</h2>
                  <div className="text-sm text-gray-800 flex flex-col gap-4 w-full ">
                    <ActionButton
                      icon={
                        <FontAwesomeIcon
                          icon={faHashtag}
                          className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                        />
                      }
                      title="Analyse key themes"
                      description="Examine key themes extracted from the case facts."
                      onClick={() => setThemeTourOpen(true)}
                      index={0}
                    />
                    <ActionButton
                      icon={
                        <FontAwesomeIcon
                          icon={faTimeline}
                          className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                        />
                      }
                      title="Create chronology"
                      description="Construct a timeline of key events."
                      onClick={() => setChronologyExplorerOpen(true)}
                      index={1}
                    />
                    <ActionButton
                      icon={
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="text-gray-800 w-4 h-4 cursor-pointer mr-2 border p-2 rounded-full"
                        />
                      }
                      title="Explore the facts"
                      description="Use our agent to search and interrogate your documents."
                      onClick={handleExploreClicked}
                      index={2}
                    />
                  </div>
                </div>

                <div className="relative bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg py-4 w-3/4">
                  <h2 className=" title-font font-medium text-lg mb-4 border-2 border-x-0 border-t-0 pb-1">Overview</h2>
                  <div className="flex gap-4 w-full text-sm text-gray-600 px-1">
                    <div className="flex flex-col gap-4 w-1/3 border-2 border-y-0 border-l-0 pr-10">
                      <p className="text-base font-semibold border border-x-0 border-t-0">
                        <FontAwesomeIcon icon={faChartSimple} className="text-gray-800 w-4 h-4 mr-2" />
                        Totals
                      </p>
                      <StatsTable
                        stats={{
                          factsExtracted: overview?.caseOverview?.totalFacts || 0,
                          relevantFacts: overview?.caseOverview?.relevantFacts || 0,
                          keyFacts: overview?.caseOverview?.keyFacts || 0,
                          documents: overview?.caseOverview?.documents || 0,
                          pages: overview?.caseOverview?.pages || 0,
                        }}
                        onClickHandlers={{
                          factsExtracted: handleViewFacts,
                          relevantFacts: handleViewFacts,
                          keyFacts: handleViewKeyFacts,
                          documents: handleViewDocs,
                          pages: handleViewDocs,
                        }}
                        tooltips={{
                          factsExtracted: 'Total number of facts extracted from the documents.',
                          relevantFacts: 'Number of facts deemed relevant to the case synopsis.',
                          keyFacts: 'Number of key facts extracted from the documents.',
                          documents: 'Total number of documents in the case.',
                          pages: 'Total number of pages across the documents in the case.',
                        }}
                      />
                    </div>

                    <div className="text-sm flex flex-col gap-4 w-2/3 pl-4">
                      <p className="text-base font-semibold border border-x-0 border-t-0 flex items-center">
                        <FontAwesomeIcon icon={faFile} className="text-gray-800 w-4 h-4 mr-2" />
                        Most Relevant Documents
                        <FontAwesomeIcon
                          data-tooltip-id="relevant-docs"
                          data-tooltip-content="The top 3 most relevant documents based on the number of relevant facts extracted from them."
                          icon={faInfoCircle}
                          className="text-gray-400 w-3 h-3 cursor-pointer ml-2"
                        />
                        <Tooltip
                          id="relevant-docs"
                          opacity={1}
                          style={{
                            width: '200px',
                            textAlign: 'center',
                            padding: '8px',
                            borderRadius: '8px',
                            backgroundColor: 'white',
                            color: 'black',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            letterSpacing: '-0.3px',
                            zIndex: 1,
                          }}
                        />
                      </p>
                      <RelevantDocs docs={overview?.caseOverview?.docEvents} onClick={handleRelevantDocClick} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-8 bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
                <div id="themes" className="text-lg font-semibold mb-2 flex items-center ">
                  Themes
                  <FontAwesomeIcon
                    data-tooltip-id="theme"
                    data-tooltip-content="Themes relate to the issues highlighted in the synopsis and found in the extracted facts. Wexler probes the full set of facts by each of these issues to give an overview of the key events that relate to each issue."
                    icon={faInfoCircle}
                    className="text-gray-400 w-3 h-3 cursor-pointer ml-2"
                  />
                  <Tooltip
                    id="theme"
                    opacity={1}
                    style={{
                      width: '400px',
                      textAlign: 'center',
                      padding: '8px',
                      borderRadius: '8px',
                      backgroundColor: 'white',
                      color: 'black',
                      fontSize: '12px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '20px',
                      letterSpacing: '-0.3px',
                      zIndex: 1,
                    }}
                  />
                </div>
                <div className="flex flex-col border-2 border-gray-200 border-opacity-60 rounded-md">
                  {themeTitles.map((themeTitle, index) => (
                    <Accordion
                      id={`theme-${index}`}
                      key={index}
                      index={index}
                      title={<p className="text-blue-500 pr-12">{themeTitle.title}</p>}
                      content={
                        <ThemeContent
                          key={index}
                          id={`theme-${index}`}
                          subquestions={themeSubquestions[themeTitle.message_id]}
                          content={themeText[index]}
                          scrollToRow={scrollToRow}
                        />
                      }
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          {summaries && summaries.keyFacts && summaries.keyFacts.length > 0 && (
            <>
              <div className="mb-4 bg-gray-50 p-4 border-2 border-gray-200 border-opacity-60 rounded-lg">
                <div className="flex items-center gap-1 w-full mb-3">
                  <div className="text-lg font-semibold flex items-center">Key Facts</div>

                  <FontAwesomeIcon
                    icon={faArrowUpRightFromSquare}
                    className="text-blue-500 w-4 h-4 cursor-pointer p-2 hover:bg-gray-100 rounded-full"
                    onClick={handleViewKeyFacts}
                  />
                </div>

                <div
                  ref={keyFactsTableRef}
                  className="rounded-lg border-2 border-gray-200 border-opacity-60 max-h-[32rem] overflow-y-scroll overflow-x-visible"
                >
                  <FactsTable
                    data={summaries.keyFacts}
                    columns={keyFactColumns}
                    handleViewSingleFact={handleViewSingleFact}
                  />
                </div>
              </div>
              <div id="after-key-facts-table"></div>
            </>
          )}
          <Tour startAt={0} steps={THEME_STEPS} isOpen={themeTourOpen} onRequestClose={() => setThemeTourOpen(false)} />
          <Modal
            handleClose={() => {
              setChronologyExplorerOpen(false);
            }}
            isOpen={chronologyExplorerOpen}
            content={
              <ChronologyModal
                totalKeyFacts={overview?.caseOverview?.keyFacts}
                totalRelevantFacts={overview?.caseOverview?.relevantFacts}
                caseId={caseId}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default CaseSummary;
