import React, { useState, useEffect, useRef } from 'react';

import { CHRONOS_TABS } from 'constants/chronosTabs';
import { useNavigate, useLocation } from 'react-router-dom';

interface NavigationTabsProps {
  caseId: string;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ caseId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentView = location.pathname.split('/').pop();
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsRef = useRef<HTMLDivElement>(null);

  const tabSelectedStyles = 'inline-flex justify-center items-center relative';
  const tabUnselectedStyles = 'inline-flex justify-center items-center';

  const handleTabClick = (e: React.MouseEvent, path: string) => {
    navigate(path);
  };

  useEffect(() => {
    if (tabsRef.current) {
      const currentTab = tabsRef.current.querySelector(`[data-tab="${currentView}"]`) as HTMLElement;
      if (currentTab) {
        setIndicatorStyle({
          left: currentTab.offsetLeft,
          width: currentTab.clientWidth,
        });
      }
    }
  }, [currentView]);

  return (
    <div className="w-full flex justify-between items-center">
      <div
        className="relative flex flex-row mt-2 mb-5 border border-gray-300 border-x-0 border-t-0 border-b-1 w-fit"
        ref={tabsRef}
      >
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/summary?caseId=${caseId}`)}
          className={`relative cursor-pointer py-2 px-2 ${
            currentView === CHRONOS_TABS.SUMMARY ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.SUMMARY}
          data-testid="summary"
        >
          Kim
          <div className="absolute top-1 right-[-10px] animate-popIn">
            <svg
              fill="rgb(235 179 5)"
              stroke="rgb(235 179 5)"
              height="18px"
              width="18px"
              version="1.1"
              id="_x32_"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <g>
                <path
                  d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917
		c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9
		c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904
		C315.08,148.422,272.014,131.551,247.355,106.9z"
                />
                <path
                  d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872
		c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864
		c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82
		C447.939,329.14,422.201,319.061,407.471,304.339z"
                />
                <path
                  d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027
		c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626
		c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019
		C159.024,395.966,150.555,374.351,146.352,354.702z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/documents?caseId=${caseId}`)}
          className={`cursor-pointer py-2 px-6 ${
            currentView === CHRONOS_TABS.DOCUMENTS ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.DOCUMENTS}
          data-testid="documents"
        >
          Documents
        </div>
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/facts?caseId=${caseId}`)}
          className={`cursor-pointer py-2 px-6 ${
            currentView === CHRONOS_TABS.FACTS ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.FACTS}
          data-testid="facts"
        >
          Facts
        </div>
        <div
          onClick={(e) => handleTabClick(e, `/app/chronos/case-editor/chronology?caseId=${caseId}`)}
          className={`cursor-pointer py-2 px-6 ${
            currentView === CHRONOS_TABS.CHRONOLOGY ? tabSelectedStyles : tabUnselectedStyles
          }`}
          data-tab={CHRONOS_TABS.CHRONOLOGY}
          data-testid="chronologies"
        >
          Chronologies
        </div>

        {/* <div
        onClick={() => navigate(`/app/chronos/case-editor/assistant?caseId=${caseId}`)}
        className={`cursor-pointer py-2 px-2 ${
          currentView === CHRONOS_TABS.ASSISTANT ? tabSelectedStyles : tabUnselectedStyles
        }`}
        data-tab={CHRONOS_TABS.ASSISTANT}
      >
        Kim{' '}
        <div
          className="text-xs bg-white rounded-lg ml-1 mt-1 font-semibold"
          style={{ color: '#050C42', fontSize: '10px', padding: '0.5px 10px' }}
        >
          Beta
        </div>
      </div> */}

        <div className="absolute bottom-0 h-0.5 bg-blue-500 transition-all duration-300" style={indicatorStyle} />
      </div>
    </div>
  );
};

export default NavigationTabs;
