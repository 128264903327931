import { APIBaseChronos } from 'api/hosts';
import useGetFetchConfig from 'api/useGetFetchConfig';
import { useQuery } from 'react-query';

export interface AuditTrailData {
  event_id: string;
  edit_id: string;
  edited_date: string;
  editor_id: string;
  editor_email?: string;

  // Current values
  action_described: string;
  date_of_subject: string;
  date_of_subject_is_ambiguous: boolean;
  date_of_subject_is_ambiguous_text: string | null;
  date_of_subject_text: string;
  document_date: string;
  document_date_is_ambiguous: boolean;
  document_date_is_ambiguous_text: string | null;
  document_date_text: string;
  important: string;
  included: boolean;
  is_future_date: boolean;
  relevance_reason: string;
  subject_matter: string;

  // Nested previous values
  previous: {
    action_described: string;
    date_of_subject: string;
    date_of_subject_is_ambiguous: boolean;
    date_of_subject_is_ambiguous_text: string | null;
    date_of_subject_text: string;
    document_date: string;
    document_date_is_ambiguous: boolean;
    document_date_is_ambiguous_text: string | null;
    document_date_text: string;
    important: string;
    included: boolean;
    is_future_date: boolean;
    relevance_reason: string;
    subject_matter: string;
  };

  // Nested original values
  original: {
    action_described: string;
    date_of_subject: string;
    date_of_subject_is_ambiguous: boolean;
    date_of_subject_is_ambiguous_text: string | null;
    date_of_subject_text: string;
    document_date: string;
    document_date_is_ambiguous: boolean;
    document_date_is_ambiguous_text: string | null;
    document_date_text: string;
    important: string;
    included: boolean;
    is_future_date: boolean;
    relevance_reason: string;
    subject_matter: string;
  };
}

interface AuditTrailResponse {
  eventEdits: AuditTrailData[];
  page: number;
  pageSize: number;
  totalPages: number;
}

function useAuditTrailData(caseId: string | null | undefined, page: number, pageSize: number) {
  const { fetchConfigGET } = useGetFetchConfig();

  const { data, error, isLoading, isFetching, refetch } = useQuery<AuditTrailResponse, Error>(
    ['auditTrail', caseId, page, pageSize],
    async () => {
      const response = await fetch(
        `${APIBaseChronos}/client/case/fact/auditTrail/${caseId}?page=${page}&pageSize=${pageSize}`,
        fetchConfigGET,
      );
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    },
    { enabled: false, cacheTime: 0 },
  );

  return { data, error, isLoading, isFetching, refetch };
}

export default useAuditTrailData;
